import React from 'react';
import {
    RisePage,
    RiseAppBar,
    FormWrapper,
    riseStrings,
    useSelector,
    FormSlice
} from '@optum-uhone-hmkts/rise';
import { jwt_auth } from '../../../../utilities/auth';


export const FactFinderFormPage: React.FC<{}> = () => {
    const displayConfirmation = useSelector(app => {
        const state = FormSlice.selectState(app);
        return !!state.activeForm.hasUnsavedData;
    });
    const { hasPermission } = jwt_auth.checkPermissions(['pFactFinder']);
    return (
        !hasPermission ? null :
            <RisePage
                path={riseStrings._Route_Form_View}
            >
                <RiseAppBar
                    position="sticky"
                    backAction

                    confirmBack={displayConfirmation
                        ? {
                            titleText: "This form has unsaved data",
                            subtitleText: "Are you sure you want to go back?"
                        } : undefined}

                />
                <FormWrapper />

            </RisePage >

    );
}