// import "react-app-polyfill/ie11";
// import "react-app-polyfill/stable";
import { initializeRise, riseStrings } from '@optum-uhone-hmkts/rise';
import { AgentConnect } from './components/nav/Routes';
import { isMobileDevice, isTablet } from './utilities/is_mobile';
import { setupResponsiveBehavior, doOnResizeWindow } from './utilities/window_size';
import { setupGlobalExceptionHandler } from './utilities/logging_util';
import { reduxInitialState, appReducer } from './reducers';
import { setupConnectivitySnackBars } from './utilities/event_listeners/connectivity_util';
import { setupBranding } from './utilities/branding/util';
import { getTheme } from './utilities/theme_util';
import { rootSaga } from './sagas';
import momentDurationFormatSetup from 'moment-duration-format';
import moment from 'moment';
import { FactFinderSlice } from './slices';
import './assets/fonts/style.css';
import './index.css';
import { AppConfiguration, loadAppConfiguration } from './types/config';
import { StrictMode } from 'react';

declare global {
	interface Window {
		ga: any;
		Plotly: any;
	}

	const VERSION: string;
}

// TODO: remove these completely - no longer needed for pwa (used for mobile binaries only)
export let SQLCipher: any = null;
export let SecureStorage: any = null;
var nav = {
	push: function (route) { },
	goBack: function () { },
	replace: function (route) { },
};
var getAppState = function (): any { };
var render = function (component, element) { };
var store;


export { store, getAppState, nav };

const afterRender = () => {
	setupGlobalExceptionHandler();
	doOnResizeWindow();

	if (!isMobileDevice || isTablet()) {
		document.body.classList.add('desktop');
	}
};

// Application setup
loadAppConfiguration()
	.then((config: AppConfiguration) => {

		setupBranding().then(() => {
			({ render, store, getAppState, nav } = initializeRise({
				storeOptions: {
					disableSerializableCheck: true,
					rootSaga: rootSaga,
					rootReducer: appReducer,
					initialState: reduxInitialState,
					ignoredActions: ['Authentication/Update_Idle'],
				},
				startupConfig: {
					environment: config.environment || "dev",
					enterpriseName: "HealthMarkets",
					registryUrl: `${config.rise_registry_url}/api/`,
					applicationName: "AgentConnect",
				},
				slices: [
					FactFinderSlice
				],
				placeholders: [
					{
						id: "DesktopHomeWidget5",
						widgets: ["FactFinder-" + riseStrings._FactFinderDashboardWidget],
					},
					{
						id: "MobileHomeWidget5",
						widgets: ["FactFinder-FactFinderBubbleItem"],
					}
				],
				placeholderProps: [
					{
						id: "FactFinderFabItem",
						widgetPropsId: 'FactFinder-FactFinderFabItem',
					},
				],
				AppComponent: AgentConnect,
			}));


			momentDurationFormatSetup(moment);
			setupConnectivitySnackBars();
			setupResponsiveBehavior();

			render(afterRender, getTheme());

		});
	})
	.catch(() => {
		render(
			<StrictMode>
				<p>Currently not available...</p>
			</StrictMode>,
			document.getElementById('root'),
		);
	});