import { isType } from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';
import { SyncCalendar } from '../actions/user_actions';
import { CalendarSignInCode, DisableCalendarSync, VerifyCalendarSignIn } from '../actions/calendar_actions';

export interface CalendarSignInResponse {
	device_code: string;
	user_code: string;
	verification_url: string;
	expires_in: number;
	interval: number;
}

export interface CalendarVerifiedResponse {
	access_token: string;
	expires_in: number;
	token_type: string;
	refresh_token: string;
}

export interface CalendarState {
	isLoading: boolean;
	calendarSignInCode: string;
	verifyCalendarSignInDone: boolean;
	calendarVerificationUrl: string;
	calendarSignInCodeDone: number;
	calendarDeviceCode: string;
	calendarPollInterval: number;
	calendarPollTimeout: number;
	calendarSyncDisabledSuccessfully?: boolean;
	access_token: string;
	expires_in: number;
	token_type: string;
	refresh_token: string;
}

export const initialState: CalendarState = {
	isLoading: false,
	calendarSignInCode: '',
	verifyCalendarSignInDone: false,
	calendarVerificationUrl: '',
	calendarSignInCodeDone: 0,
	calendarDeviceCode: '',
	calendarPollInterval: 0,
	calendarPollTimeout: 0,
	access_token: '',
	expires_in: 0,
	token_type: '',
	refresh_token: '',
};

export function calendarReducer(
	state: CalendarState = initialState,
	action: ReduxAction
): CalendarState {
	if (isType(action, CalendarSignInCode.started)) {
		return {
			...state,
			isLoading: true,
			calendarSignInCode: '',
			calendarVerificationUrl: '',
			calendarSignInCodeDone: 0,
			calendarDeviceCode: '',
			calendarPollInterval: 0,
			calendarPollTimeout: 0,
		};
	} else if (isType(action, CalendarSignInCode.done)) {
		const result = action.payload.result;
		return {
			...state,
			isLoading: false,
			calendarSignInCode: result.user_code,
			calendarVerificationUrl: result.verification_url,
			calendarSignInCodeDone: 100,
			calendarDeviceCode: result.device_code,
			calendarPollInterval: result.interval,
			calendarPollTimeout: result.expires_in,
		};
	} else if (isType(action, CalendarSignInCode.failed)) {
		return {
			...state,
			isLoading: false,
			calendarSignInCode: '',
			calendarVerificationUrl: '',
			calendarSignInCodeDone: 100,
			calendarDeviceCode: '',
			calendarPollInterval: 0,
			calendarPollTimeout: 0,
		};
	} else if (isType(action, VerifyCalendarSignIn.started)) {
		return {
			...state,
			verifyCalendarSignInDone: false,
			access_token: '',
			expires_in: 0,
			token_type: '',
			refresh_token: '',
		};
	} else if (isType(action, VerifyCalendarSignIn.done)) {
		const result = action.payload.result;
		return {
			...state,
			verifyCalendarSignInDone: true,
			access_token: result.access_token,
			expires_in: result.expires_in,
			token_type: result.token_type,
			refresh_token: result.refresh_token,
			};
	} else if (isType(action, VerifyCalendarSignIn.failed)) {
		return {
			...state,
			verifyCalendarSignInDone: false,
			access_token: '',
			expires_in: 0,
			token_type: '',
			refresh_token: '',
		};		
	} else if (isType(action, DisableCalendarSync.started)) {
		return {
			...state,
			calendarSyncDisabledSuccessfully: undefined,
			isLoading: true,
		};
	} else if (isType(action, DisableCalendarSync.done)) {
		return {
			...state,
			calendarSyncDisabledSuccessfully: true,
			isLoading: false,
		};
	} else if (isType(action, DisableCalendarSync.failed)) {
		return {
			...state,
			calendarSyncDisabledSuccessfully: false,
			isLoading: false,
		};
	} else if (
		isType(action, SyncCalendar.done)
	) {
		return {
			...initialState,
		};
	} else {
		return state;
	}
}
