import { actionCreatorFactory } from 'typescript-fsa';
import { CalendarSignInResponse, CalendarVerifiedResponse } from '../reducers/calendar_reducer';

const actionCreator = actionCreatorFactory('Calendar');

export const CalendarSignInCode = actionCreator.async<void, CalendarSignInResponse>('GET_CALENDAR_SIGN_IN_CODE');

export const DisableCalendarSync = actionCreator.async<void, void>('DISABLE_CALENDAR_SYNC');

export interface VerifyCalendarSignInParams {
	calendarDeviceCode: string;
	calendarPollInterval: number;
	calendarPollTimeout: number;
}
export const VerifyCalendarSignIn = actionCreator.async<VerifyCalendarSignInParams, CalendarVerifiedResponse>(
	'VERIFY_CALENDAR_SIGN_IN'
);
