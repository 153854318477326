import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import { isMobileDevice } from '../utilities/is_mobile';
import {
	UpdateIdle,
	ShowPin,
	PinEnabled,
	TouchIDEnabled,
	PauseTimeout,
	DisableFirstTimeLoginPrompt,
	SetLoginInfo,
	EnableFirstTimeLoginPrompt,
	DisplayConfirmUpdatePin,
	ConfirmUpdatePin,
	FinishFirstTimeLogin,
	SecureStorageAvailable,
	setIdledDialogVisible,
	Authenticate,
	ResetAuthTimer,
	IncrementAuthTimer,
} from '../actions/authentication_actions';
import { CancelLogin, CheckVersion, FailedLogin, FailedTouchId, ImpersonateJwt, Logout, NewLogin, StoreJwt, SuccessfulLogin } from '../actions/authentication_actions';
import { Strings } from '../assets/common/strings';
import { jwt_auth } from '../utilities/auth';
import { getDeviceId } from '../utilities/device_util';

export enum VisibilityState { Disabled, Hidden, Visible };

export type Permission = {
    ADGroup?: string;
    permissionCode?: string;
    scopeType?: string;
    impersonable?: boolean;
    uiState?: VisibilityState;
}

export type PermissionDetail = {
    permissionCode?: string;
    scopeType?: string;
    impersonable?: boolean;
    uiState?: VisibilityState;
}

export type RolePermission = {
    role?: string;
    permissions: PermissionDetail[];
}

export interface DecodedJwt {
    exp: number;
    agentID: string;
    userClaims: { [claim: string]: string[] }
    agency?: string;
    channel?: string;
    iat?: number;
    impersonatingId?: string;
    userID?: string;
    permissions?: Permission[];
    rolePermissions?: RolePermission[];
    roles?: string[];
    fileStorageSignature?: string;
    featureFlags?: string[];
}

export interface AuthenticationState {
	isAuthenticated: boolean;
	logoutPending: boolean;
	timeout: number;

	shouldShowTouchPinAuth: boolean;
	pauseTimeout: boolean;
	timeWhenIdleStarted: number;
	needsPin: boolean;
	touchIDEnabled: boolean;
	pinCodeEnabled: boolean;
	renewingToken: boolean;
	webCanHandleNotifications: boolean;
	firstTimeLoginNotifications: boolean;
	firstTimeLogin: boolean;
	upn: string;
	showConfirmUpdatePin: boolean;
	secureStorageAvailable: boolean;
	idledDialogOpen: boolean;



    //login
    isImpersonating: boolean;
    errorMessage: string;
    deviceId?: string;
    fedAuthCookie?: string;
    updateAvailable: boolean;
    newVersionUrl: string;
    jwt: string;
    loginState: Strings.LoginState;
    decodedJwt?: DecodedJwt;
    firstLogin: boolean;
}

const initialState: AuthenticationState = {
	isAuthenticated: false,
	logoutPending: false,
	timeout: 0, // number of seconds passed

	//user data
	touchIDEnabled: false,
	needsPin: false,
	pinCodeEnabled: false,
	webCanHandleNotifications: false,
	firstTimeLoginNotifications: false,
	firstTimeLogin: false,
	upn: '',

	//loading flags
	pauseTimeout: false,
	renewingToken: false,

	//status flags
	timeWhenIdleStarted: Date.now(),


	//display flags
	shouldShowTouchPinAuth: true,
	showConfirmUpdatePin: false,
	secureStorageAvailable: false,

	idledDialogOpen: false,




    //login
    isImpersonating: false,
    updateAvailable: false,
    newVersionUrl: '',
    deviceId: undefined,
    errorMessage: '',
    fedAuthCookie: '',
    jwt: '',
    loginState: Strings.LoginState.DisplayForm,
    decodedJwt: undefined,
    firstLogin: true,
};

export function authenticationReducer(
	state: AuthenticationState = initialState,
	action: ReduxAction
): AuthenticationState {
	if (isType(action, ResetAuthTimer)) {
		return {
			...state,
			timeout: 0,
		};
	} else if (isType(action, IncrementAuthTimer)) {
		return {
			...state,
			timeout: state.timeout + action.payload,
		};
	} if (isType(action, UpdateIdle)) {
		return {
			...state,
			timeWhenIdleStarted: Date.now(),
		};
	} else if (isType(action, setIdledDialogVisible)) {
		return {
			...state,
			idledDialogOpen: action.payload,
		};
	} else if (isType(action, EnableFirstTimeLoginPrompt)) {
		return {
			...state,
			firstTimeLoginNotifications:
				isMobileDevice || state.webCanHandleNotifications ? true : false,
			firstTimeLogin: true,
		};
	} else if (isType(action, DisableFirstTimeLoginPrompt)) {
		return {
			...state,
			firstTimeLoginNotifications: false,
		};
	} else if (isType(action, FinishFirstTimeLogin)) {
		return {
			...state,
			firstTimeLogin: false,
		};
    } else if (isType(action, CheckVersion.started)) {
        return {
            ...state,
            loginState: Strings.LoginState.CheckingForUpdates,
        };
    } else if (isType(action, CheckVersion.done)) {
        return {
            ...state,
            updateAvailable: action.payload.result.Deprecated,
            newVersionUrl: action.payload.result.NewVersionURL,
            firstLogin: false,
        };
    } else if (isType(action, NewLogin)) { //Start Login
        return {
            ...state,
            loginState: Strings.LoginState.PreparingExperience,
        };
    } else if (isType(action, Authenticate.started)) { //Start authentication
        return {
            ...state,
            loginState: state.logoutPending ? Strings.LoginState.LogoutPending : Strings.LoginState.CheckingAuth,
        };
    } else if (isType(action, Authenticate.done)) { //Finished authentication
        const isAuthenticated = action.payload.result;
        return {
            ...state,
            isAuthenticated,
            loginState: isAuthenticated ? Strings.LoginState.PreparingExperience : Strings.LoginState.DisplayForm,
        };
    } else if (isType(action, SuccessfulLogin)) { //Complete Login
        const { jwt, fedAuthCookie } = action.payload;
        const decodedJwt = jwt ? jwt_auth.decodeJwt(jwt) : undefined;
        return {
            ...state,
            jwt,
            decodedJwt,
            fedAuthCookie,
            errorMessage: '',
            isAuthenticated: true,
            isImpersonating: !!decodedJwt && !!decodedJwt.impersonatingId,
            deviceId: getDeviceId(),
            loginState: Strings.LoginState.Authenticated,
            firstLogin: false,
            timeWhenIdleStarted: Date.now(),
			timeout: 0,
        };
    } else if (isType(action, Logout.started) || isType(action, CancelLogin) || isType(action, Logout.done) || isType(action, FailedLogin)) {
        return {
            ...initialState,
            firstLogin: false,
            updateAvailable: state.updateAvailable,
            newVersionUrl: state.newVersionUrl,
            loginState: isType(action, Logout.started) ? Strings.LoginState.LogoutPending : initialState.loginState,
            logoutPending: isType(action, Logout.started),
			isAuthenticated: false,
            errorMessage: isType(action, FailedLogin) ? action.payload.error : initialState.errorMessage,
        };
    } else if (isType(action, StoreJwt) || isType(action, ImpersonateJwt)) {
        const jwt = action.payload;
        const decodedJwt = jwt ? jwt_auth.decodeJwt(jwt) : undefined;

        return {
            ...state,
            jwt,
            decodedJwt,
        };
	} else {
		return state;
	}
}
