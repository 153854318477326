import {
	LinearProgress,
	Switch,
	Button,
	Icon,
	Dialog,
	DialogContent,
	DialogTitle,
	DialogActions,
	DialogContentText,
	TextField,
	Grid,
} from '@material-ui/core';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { themePalette } from '../../utilities/branding';
import { AppState } from '../../reducers/index';
import { navigateTo } from '../../actions/navigation_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import {
	DisableCalendarSync,
	CalendarSignInCode,
	VerifyCalendarSignIn,
	VerifyCalendarSignInParams,
} from '../../actions/calendar_actions';
import { isNullOrUndefinedOrEmpty } from '../../utilities/utilities';
import { User } from '../../reducers/user_reducer';
import {
	StoreCalendarSettings,
	getUserById,
} from '../../actions/user_actions';
import { CalendarState, CalendarVerifiedResponse } from '../../reducers/calendar_reducer';
import { QueueSnackbar, SnackbarProps } from '../../actions/snackbar_actions';
import { copyToClipboard, isIOS } from '../../utilities/is_mobile';
import { getSnackbarSuccessProps } from '../../utilities/snackbar_util';
import { Dispatch } from '@reduxjs/toolkit';
import { openExternalLink } from '../../utilities';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { ToggleCard } from '../../components/higher_order_component/wrap_in_card';
import { SimpleListItem } from '../../components/utility/simple_list_item';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PhoneIcon from '@material-ui/icons/Phone';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { AppConfig } from '../../types/config';

interface StateProps {
	calendarState: CalendarState;
	user: User;
	isLoading: boolean;
}
interface DispatchProps {
	navigateTo: (route: string) => void;
	calendarSignInCode: () => void;
	verifyCalendarSignIn: (verifyCalendarSignInDto: VerifyCalendarSignInParams) => void;
	storeCalendarSettings: (user: User) => void;
	disableCalendarSync: () => void;
	getUser: (id: string, sync?: boolean) => void;
    queueSnackbar: (snackbarProps: SnackbarProps) => void;
}
type CalendarSyncPageProps = StateProps & DispatchProps;

interface CalendarSyncState {
	isSignInDialogOpen: boolean;
	isDisableDialogOpen: boolean;
	syncCalendar: boolean;
	appointmentsEnabled: boolean;
	phoneCallsEnabled: boolean;
	tasksEnabled: boolean;
	calendarVerifiedResponse: CalendarVerifiedResponse;
	isPreSync: boolean;
}

class CalendarSyncPage extends React.Component<
	CalendarSyncPageProps,
	CalendarSyncState
	> {
	timer: any;
	signInStarted: boolean;
	constructor(props: CalendarSyncPageProps) {
		super(props);
		this.state = {
			isSignInDialogOpen: false,
			isDisableDialogOpen: false,
			syncCalendar: !isNullOrUndefinedOrEmpty(this.props.user
				.googleRefreshToken as string),
			appointmentsEnabled: this.props.user.syncGoogleAppointments || false,
			phoneCallsEnabled: this.props.user.syncGooglePhoneCalls || false,
			tasksEnabled: this.props.user.syncGoogleTasks || false,
			calendarVerifiedResponse: {
				access_token: '',
				expires_in: 0,
				token_type: '',
				refresh_token: '',
			},
			isPreSync: false,
		};
	}

	componentWillMount() {
		this.props.getUser(this.props.user.id, false);
	}

	componentWillReceiveProps(nextProps: CalendarSyncPageProps) {
		const { calendarSyncDisabledSuccessfully } = nextProps.calendarState;
		this.setState({
			appointmentsEnabled: nextProps.user.syncGoogleAppointments || false,
			phoneCallsEnabled: nextProps.user.syncGooglePhoneCalls || false,
			tasksEnabled: nextProps.user.syncGoogleTasks || false,
		});

		if (calendarSyncDisabledSuccessfully === true) {
			const clearedCalendarVerifiedState: CalendarVerifiedResponse = {
				access_token: '',
				expires_in: 0,
				token_type: '',
				refresh_token: '',
			};
			this.setState({
				calendarVerifiedResponse: clearedCalendarVerifiedState,
			});
		}
	}

	componentWillUnmount() {
		window.clearInterval(this.timer);
	}

	createCalendarSignInCodeDisplay = () => {
		const {calendarSignInCodeDone, calendarSignInCode} = this.props.calendarState;
		if (calendarSignInCodeDone === 100) {
			const _calendarSignInCode = String(calendarSignInCode);
			return isNullOrUndefinedOrEmpty(_calendarSignInCode) ? 'Error' : _calendarSignInCode;
		} else {
			return '';
		}
	};

	disableCalendarSync = () => {
		this.closeDisableDialog();
		window.clearInterval(this.timer);
		this.props.disableCalendarSync();
	};

	closeSignInDialog = () => {
		this.setState({ isSignInDialogOpen: false });
	};

	closeDisableDialog = () => {
		this.setState({ isDisableDialogOpen: false });
	};

	openDialog = () => {
		if (
			isNullOrUndefinedOrEmpty(this.props.user.googleRefreshToken as string)
		) {
			this.signInStarted = true;
			this.setState({ isSignInDialogOpen: true, isDisableDialogOpen: false });
			this.props.calendarSignInCode();
		} else {
			this.setState({ isDisableDialogOpen: true, isSignInDialogOpen: false });
		}
	};

	verifyCalendarSignIn = () => {
		const { calendarDeviceCode, calendarPollInterval, calendarPollTimeout } = this.props.calendarState;
		if (calendarDeviceCode) {
			this.signInStarted = true;
			let verifyCalendarSignInDto = {
				calendarDeviceCode, calendarPollInterval, calendarPollTimeout 
			} as VerifyCalendarSignInParams;
			this.props.verifyCalendarSignIn(verifyCalendarSignInDto);
		}
	}

	verifyCalendarSignInDone = () => {
		const { access_token, expires_in, token_type, refresh_token } = this.props.calendarState;
		if (this.signInStarted) {
			this.setState({
				calendarVerifiedResponse: {
					access_token,
					expires_in,
					token_type,
					refresh_token,
				},
				isPreSync: true,
			});
			this.signInStarted = false;
		}
		QueueSnackbar(getSnackbarSuccessProps('Google Calendar successfully verified'));
	}

	handleSignInClick = () => {
		this.closeSignInDialog();
		this.navigateToGoogle();
	};

	navigateToGoogle = () => {
		const { calendarVerificationUrl } = this.props.calendarState;
		this.verifyCalendarSignIn();
		openExternalLink(calendarVerificationUrl);
	};

	savePreferences = () => {
		let newUserSettings = this.props.user;
		newUserSettings.googleRefreshToken = !isNullOrUndefinedOrEmpty(this.props
			.user.googleRefreshToken as string)
			? this.props.user.googleRefreshToken
			: this.state.calendarVerifiedResponse.refresh_token;
		newUserSettings.syncGoogleAppointments = this.state.appointmentsEnabled;
		newUserSettings.syncGooglePhoneCalls = this.state.phoneCallsEnabled;
		newUserSettings.syncGoogleTasks = this.state.tasksEnabled;
		this.props.storeCalendarSettings(newUserSettings);
		this.setState({ isPreSync: false });
	};

	toggleAppointmentsEnabled = () => {
		this.setState({ appointmentsEnabled: !this.state.appointmentsEnabled });
	};

	togglePhoneCallsEnabled = () => {
		this.setState({ phoneCallsEnabled: !this.state.phoneCallsEnabled });
	};

	toggleTasksEnabled = () => {
		this.setState({ tasksEnabled: !this.state.tasksEnabled });
	};

	createEnableDisableDisplay = () => {
		if (
			!isNullOrUndefinedOrEmpty(this.props.user.googleRefreshToken as string)
		) {
			return 'Enabled';
		} else {
			if (
				!isNullOrUndefinedOrEmpty(
					this.state.calendarVerifiedResponse.refresh_token
				) &&
				this.state.isPreSync
			) {
				return 'Sync Now to Enable';
			}
			return 'Disabled';
		}
	};

	renderSignInDialog() {
		const {calendarSignInCodeDone} = this.props.calendarState;
        const calendarCode = this.createCalendarSignInCodeDisplay();
		return (
			<Dialog open={this.state.isSignInDialogOpen}>
				{calendarSignInCodeDone != 100 ? (
					<LinearProgress />
				) : null}
				<DialogTitle>
					Sync To-Dos with Google Calendar
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Copy this code for Google Sign In:
					</DialogContentText>
					<TextField
						style={{ color: themePalette.primary_text }}
						id="googleCal"
						value={calendarCode}
					/>
					<Icon
						onClick={() => {
                            copyToClipboard(calendarCode || '');
                            this.props.queueSnackbar(getSnackbarSuccessProps('Copied URL to clipboard', 2000));
                        }}
					>
						file_copy
					</Icon>
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={this.closeSignInDialog}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleSignInClick}
						disabled={isNullOrUndefinedOrEmpty(String(calendarSignInCodeDone))}
						style={{ backgroundColor: themePalette.accept_button }}
					>
						Sign In
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	renderDisableDialog() {
		return (
			<Dialog open={this.state.isDisableDialogOpen}>
				<DialogTitle>
					Disable Google Sync
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to discontinue syncing with Google Calendar?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={this.closeDisableDialog}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={this.disableCalendarSync}
						style={{ backgroundColor: themePalette.delete_remove_reject_button }}
					>
						Disable
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	render() {
		const { verifyCalendarSignInDone } = this.props.calendarState;
		if (verifyCalendarSignInDone === true) {
			this.verifyCalendarSignInDone();
		}

		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title="Settings"
						isLoading={
							this.signInStarted == true || this.props.isLoading
						}
					/>
				}
			>
				<PersistentNotifications />
				<DesktopPadding>
					<ToggleCard title={'Google Calendar Settings'} noPadding>
						<SimpleListItem
							title="Google Calendar Sync"
							subtitle={this.createEnableDisableDisplay()}
							secondaryAction={
								<Switch
									color="primary"
									onClick={this.openDialog}
									checked={
										!isNullOrUndefinedOrEmpty(this.props.user.googleRefreshToken as string) ||
										!isNullOrUndefinedOrEmpty(this.state.calendarVerifiedResponse.refresh_token)
									}
									disabled={ 
										this.signInStarted == true &&
										isNullOrUndefinedOrEmpty(this.state.calendarVerifiedResponse.refresh_token)
									}
								/>
							}
						/>
						{(!isNullOrUndefinedOrEmpty(this.props.user.googleRefreshToken as string) ||
							!isNullOrUndefinedOrEmpty(this.state.calendarVerifiedResponse.refresh_token)) && (
								<>
									<SimpleListItem subtitle={'To-Dos'} />
									<SimpleListItem 
										title={'Appointments'}
										icon={<DateRangeIcon/>}
										secondaryAction={
											<Switch
												color="primary"
												checked={this.state.appointmentsEnabled}
												onChange={this.toggleAppointmentsEnabled}
											/>
										}
									/>
									<SimpleListItem 
										title={'Phone Calls'}
										icon={<PhoneIcon/>}
										secondaryAction={
											<Switch
												color="primary"
												checked={this.state.phoneCallsEnabled}
												onChange={this.togglePhoneCallsEnabled}
											/>
										}
									/>
									<SimpleListItem 
										title={'Tasks'}
										icon={<EventAvailableIcon/>}
										secondaryAction={
											<Switch
												color="primary"
												checked={this.state.tasksEnabled}
												onChange={this.toggleTasksEnabled}
											/>
										}
									/>
									<Grid container>
										<Button
											variant="contained"
											color="primary"
											fullWidth
											style={{ backgroundColor: themePalette.accept_button, margin: 20 }}
											onClick={this.savePreferences}
										>
											<div>Sync Now</div>
										</Button>
									</Grid>
								</>
							)
						}
					</ToggleCard>
				</DesktopPadding>
				{this.renderDisableDialog()}
				{this.renderSignInDialog()}
			</BasePageContainer>
		);
	}
}

const mapStateToProps = (state: AppState): StateProps => ({
	calendarState: state.calendar,
	user: state.user,
	isLoading: state.activity.isLoading,
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<CalendarSyncPageProps> => ({
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
	calendarSignInCode: () => dispatch(CalendarSignInCode.started()),
	verifyCalendarSignIn: (verifyCalendarSignInDto: VerifyCalendarSignInParams) => 
		dispatch(VerifyCalendarSignIn.started(verifyCalendarSignInDto)),
	storeCalendarSettings: (user: User) =>
		dispatch(StoreCalendarSettings.started(user)),
	disableCalendarSync: () =>
		dispatch(DisableCalendarSync.started(undefined)),
	getUser: (id: string, sync?: boolean) => dispatch(getUserById(id, sync)),
    queueSnackbar: (snackbarProps: SnackbarProps) => dispatch(QueueSnackbar(snackbarProps)),
});

export const CalendarSyncContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(CalendarSyncPage);
