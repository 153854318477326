import { call, put, all } from 'redux-saga/effects';
import http, { HttpOptions } from '../utilities/http';
import { QueueSnackbar } from '../actions/snackbar_actions';
import _ from 'lodash';
import { getSnackbarErrorProps, getSnackbarSuccessProps } from '../utilities/snackbar_util';
import { takeLatest, Action } from '@optum-uhone-hmkts/rise';
import { Strings } from '../assets/common/strings';
import { CalendarSignInCode, DisableCalendarSync, VerifyCalendarSignInParams, VerifyCalendarSignIn } from '../actions/calendar_actions';

function* disableCalendarSyncSaga(action: Action<void>) {
	const successSnackbarProps = getSnackbarSuccessProps('Disable Sync was successful');
	const errorSnackbarProps = getSnackbarErrorProps('Failed to disable Google Sync');
	try {
		const response = yield call(disableCalendarSyncCall);
		if (response.ok || response.status == 204) {
			yield put(
				DisableCalendarSync.done({
					params: action.payload,
					result: undefined,
				})
			);
			yield put(
				QueueSnackbar(successSnackbarProps)
			);
		} else {
			yield put(
				DisableCalendarSync.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(
				QueueSnackbar(errorSnackbarProps)
			);
		}
	} catch (error) {
		yield put(
			DisableCalendarSync.failed({ params: action.payload, error })
		);
		yield put(
			QueueSnackbar(errorSnackbarProps)
		);
	}
}
function disableCalendarSyncCall(): Promise<any> {
	const options: HttpOptions = {
		method: Strings.Http.Post,
	};

	return http('calendar/disablecalendarsync', options);		
}

function* calendarSignInCodeSaga(action: Action<{}>) {
	const errorSnackbarProps = getSnackbarErrorProps('Failed to retrieve your Google Sign In code');
	try {
		const response = yield call(calendarSignInCodeCall());
		if (response.ok) {
			const data = yield response.json();
			yield put(
				CalendarSignInCode.done({ params: action.payload, result: data })
			);
		} else {
			yield put(
				CalendarSignInCode.failed({
					params: action.payload,
					error: { errorCode: response.status },
				})
			);
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	} catch (error) {
		yield put(CalendarSignInCode.failed({ params: action.payload, error }));
		yield put(QueueSnackbar(errorSnackbarProps));
	}
}

const calendarSignInCodeCall = () => (): Promise<any> => {	
	const options: HttpOptions = {
		method: Strings.Http.Post,
	};

	return http('calendar/calendarsignincodecall', options);	
}

function* verifyCalendarSignInSaga(action: Action<VerifyCalendarSignInParams>) {
	const errorSnackbarProps = getSnackbarErrorProps('Failed to retrieve your Google Sign In code');
	const { calendarDeviceCode, calendarPollInterval, calendarPollTimeout } = action.payload;
	if (calendarDeviceCode) {	
		try {
			let response;
			let data;
			let start = Date.now();
			const interval = calendarPollInterval * 1000;
			const end = start + calendarPollTimeout * 1000;

			do {
				response = yield call(verifyCalendarSignInCall, calendarDeviceCode);
				if (response.ok) {
					data = yield response?.json();
				} else {
					break;
				}
				pausing(interval);
				start = Date.now();
			} while (start < end && data == null);

			if (data !== null) {
				yield put(
					VerifyCalendarSignIn.done({ params: calendarDeviceCode, result: data })
				);
				yield put(
					QueueSnackbar(getSnackbarSuccessProps('Google Calendar successfully verified'))
				);

			} else {
				yield put(
					VerifyCalendarSignIn.failed({
						params: calendarDeviceCode,
						error: { errorCode: response.status },
					})
				);
				yield put(QueueSnackbar(errorSnackbarProps));
			}
		} catch (error) {
			yield put(CalendarSignInCode.failed({ params: action.payload, error }));
			yield put(QueueSnackbar(errorSnackbarProps));
		}
	}
}

const wait = (ms) => {
	return new Promise(resolve => setTimeout(resolve, ms));
}
async function pausing(pauseTime) {
	await wait(pauseTime);
}

function verifyCalendarSignInCall(calendarDeviceCode: string): Promise<any> {
	const options: HttpOptions = {
		method: Strings.Http.Post,
	};
	return http(`calendar/verifycalendarsignin?deviceCode=${calendarDeviceCode}`, options);
}

export function* calendarSagas() {
	yield all([
		takeLatest(
			DisableCalendarSync.started,
			disableCalendarSyncSaga
		),
		takeLatest(
			CalendarSignInCode.started,
			calendarSignInCodeSaga
		),
		takeLatest(
			VerifyCalendarSignIn.started,
			verifyCalendarSignInSaga
		),
	]);
}