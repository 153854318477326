import { Backdrop, Button, CircularProgress } from '@material-ui/core';
import React, { LazyExoticComponent, Suspense, useEffect, useState } from 'react';
import { RouteComponentProps, Switch, Route, useRouteMatch } from 'react-router-dom';
import { ActivityDetailContainer } from '../../containers/activity/activity_detail';
import { ActivitiesPageContainer } from '../../containers/activity/activity_list_page';
import { UpsertActivityContainer } from '../../containers/activity/upsert_activity';
import { AdvancedSearchPageContainer } from '../../containers/advanced_search/advanced_search_page';
import { AgentProfileLandingContainer } from '../../containers/AgentProfile/agent_profile_landing';
import { AgentSearchPageContainer } from '../../containers/AgentSearch/search';
import { ApplicationUpsertPageContainer } from '../../containers/Application/ApplicationUpsertPage';
import { ClientConnectContainer } from '../../containers/client_connect/client_connect_container';
import { ClientConnectActivityContainer } from '../../containers/client_connect/client_connect_activity_list';
import { UpsertContactContainer } from '../../containers/contact';
import { ContactDetailsContainer } from '../../containers/contact';
import { EmployerPageContainer } from '../../containers/employer/employer_page';
import { FinanceContainer } from '../../containers/FinancePage/FinancePage';
import { SideMenuWrapper } from '../side_menu/side_menu_wrapper';
import { HelpSupportContainer } from '../../containers/HelpSupportPage/help_and_support';
import { HomePageContainer } from '../../containers/HomePage/HomePage';
import { HouseholdPageContainer } from '../../containers/HouseholdPage/HouseholdPage';
import { ImportPageContainer } from '../../containers/ImportPage/ImportPage';
import { IntroVideoContainer } from '../../containers/intro/intro_video';
import { TermsAndConditionsContainer } from '../../containers/intro/terms_and_conditions';
import { UpsertLeadContainer } from '../../containers/lead_upsert/upsert_lead';
import { UpsertLeadEmployerContainer } from '../../containers/lead_upsert_employer';
import { LeadDashboardPageContainer } from '../../containers/lead/LeadDashboardPage';
import { LeadDetailPageContainer } from '../../containers/lead/LeadDetailPage';
import { LeadListPageContainer } from '../../containers/lead/LeadListPage';
import { ProfileLandingContainer } from '../../containers/MyProfilePage/LandingPage';
import { NotificationListDetailContainer } from '../../containers/notifications_page/notification_list_detail_page';
import { NotificationsPageContainer } from '../../containers/notifications_page/notifications_page';
import { ProductListPageContainer } from '../../containers/products/product_list_page';
import { ProductDetailPageContainer } from '../../containers/products/ProductDetailPage';
import { SettingsContainer } from '../../containers/SettingsPage/settings_page';
import { TemplateManagementContainer } from '../../containers/emails/template_management_page';
import { TemplateContentContainer } from '../../containers/emails/template_content_page';
import { TemplatePreviewContainer } from '../../containers/emails/template_preview_page';
import { TemplateSelectContainer } from '../../containers/emails/template_select_page';
import { LeadCirclePageContainer } from '../../containers/lead/lead_circle_page';
import { ToolsPageContainer } from '../../containers/ToolsPage/ToolsPage';
import { UpsertEmployerContainer } from '../../containers/upsert_employer/upsert_employer';
import { WelcomeContainer } from '../../containers/intro/welcome';
import { EmailAutomationContainer } from '../../containers/emails/email_automation_page';
import { SnackbarComponent } from '../Snackbar/snackbar_queue';
import { AuthRoute } from './auth_route';
import { submitLog } from '../../utilities/logging_util';
import { PerformanceContainer } from '../../containers/PerformancePage/performance_page';
import { TeamViewContainer } from '../../containers/TeamViewPage/teamview_page';
import { LeadSearchContainer } from '../../containers/lead_search/lead_search_container';
import { Strings } from '../../assets/common/strings';
import { DocumentsPage } from '../../containers/documents/documents_page';
import { ContactRedirectContainer } from '../../containers/contact/contact_redirect';
import { ApiPermission } from '../../utilities/auth/permissions';
import { makeEnforceMapType, makeMakeGetByKey, mapAcrossKeys, createOpenGetByKey } from '../../utilities/object_util';
import { SmsTemplateManagementPage } from '../../containers/sms/sms_template_management_page';
import { SmsTemplatePage } from '../../containers/sms/sms_template_page';
import { QuoteActivityDetailContainer } from '../../containers/QuoteActivity/QuoteActivityDetailPage';
import { ConnectedComponent, useSelector } from 'react-redux';
import { nav } from '../..';
import { PodcastListContainer } from '../../containers/podcast/podcast_list';
import { PodcastDetailContainer } from '../../containers/podcast/podcast_detail';
import { PodcastAudioComponent } from '../podcast/podcast_audio';

import { connect } from '@optum-uhone-hmkts/rise';
import { AppState } from '../../reducers';
import { IdledOutDialog } from '../dialogs/idled_dialog';
import { FactFinderContactLinkDialog } from '../../containers/lead/factfinder_contact_link_dialog';
import { FactFinderPrescriptionSearchDialog, FactFinderProviderSearchDialog } from '../../slices';
import { ConfigureClientConnectDialog } from '../../containers/client_connect/configure_clientconnect_dialog';
import { isAgentRole } from '../../utilities/agent_util';
import { PinPageContainer } from '../../containers/pin_page/pin_page';
import { ConnecturePageComponent } from '../../containers/connecture/open_connecture';
import PwaInstallDialog from '../dialogs/pwa_install_dialog';
import { LicenseAppointmentContainer } from '../../containers/agentLicenseAppointment/license_appointment';
import { CommissionStatementContainer } from '../../containers/agentCommissionStatement/commission_statement';

// CODE SPLITTING FOR ROUTES THAT ARE NOT USED OFTEN (Including Admin sections)
const BattlesContainer = React.lazy(() => import('../../containers/Battles/battles'));
const BattleContainer = React.lazy(() => import('../../containers/Battles/battle'));
const CreateBattleComponent = React.lazy(() => import('../../containers/Battles/create_battle'));
const UpsertPodcastContainer = React.lazy(() => import('../../containers/podcast/upsert_podcast'));
const ImpersonationPageContainer = React.lazy(() => import('../../containers/impersonation_Page/impersonation_page'));
const AdminLandingContainer = React.lazy(() => import('../../containers/admin/admin_landing_page'));
const AdminNotificationDetailContainer = React.lazy(() => import('../../containers/admin/notification_detail'));
const NavigationAdminPageContainer = React.lazy(() => import('../../containers/notification_admin_page/notification_admin_page'));
const NotificationEditPageContainer = React.lazy(() => import('../../containers/notification_edit_page/notification_edit_page'));
const ManageCompanyDNCPage = React.lazy(() => import('../../containers/admin/admin_company_dnc'));
const AccountManagementContainer = React.lazy(() => import('../../containers/admin/admin_account_management'));
const AccountUpsertContainer = React.lazy(() => import('../../containers/admin/admin_account_upsert'));

const SN = Strings.Navigation;

interface Props {
    isAuthenticated: boolean;
}
const AgentConnectApp: React.FC<Props> = props => {
    const [hasError, setError] = useState(false);
    const sideBarOpen: boolean = useSelector((appState: any) => appState.App?.accessPage?.drawerOpenStatus);
    let { path } = useRouteMatch()
    if (path === '/') {
        path = '';
    }

    const onError = () => {
        setError(false);
        nav.replace(navRoutes.home.path);
    }

    const componentDidCatch = (error: any, info: any) => {
        submitLog(Strings.Log.Error, `${error}; ${info}`, { error, info });
        setError(true);
    }

    const renderError = () => {
        return (
            <div style={{ textAlign: 'center' }}>
                <p>
                    We are sorry, but it seems an error occurred. Please go back and try
                    again.
                </p>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onError}
                >
                    Go back to Home
                </Button>
            </div>
        );
    }

    const renderRoutes = (key: keyof typeof navRoutes) => {
        const route = navRoutes[key];

        if (route.childRoute) {
            return null;
        }

        if (route.permissions && route.component) {
            return (
                <AuthRoute
                    key={key}
                    exact={!route.hasChildren}
                    path={path + route.path}
                    component={route.component}
                    permissions={route.permissions}
                    additionalPermission={route.additionalPermission}
                />
            );
        } else {
            return (
                <Route
                    key={key}
                    exact={!route.hasChildren}
                    path={path + route.path}
                    component={route.component}
                />
            );
        }
    }

    return hasError
        ? renderError()
        : (
            <Suspense fallback={
                <Backdrop open>
                    <CircularProgress />
                </Backdrop>
            }>
                <SideMenuWrapper key={"sidemenu-nav-component"} />
                <SnackbarComponent key={"snackbar-nav-component"} />
                <Switch>
                    {mapAcrossKeys(navRoutes, renderRoutes)}
                </Switch>
                {/* props.children below are for Rise Routes */}
                {props.isAuthenticated && <div style={sideBarOpen ? { marginLeft: 256 } : {}}>
                    {props.children}
                </div>}
                <PodcastAudioComponent />
                <IdledOutDialog />
                <ConfigureClientConnectDialog />
                <FactFinderContactLinkDialog />
                <FactFinderPrescriptionSearchDialog />
                <FactFinderProviderSearchDialog />
                <PinPageContainer />
                <PwaInstallDialog />
            </Suspense>
        );
}
const mapStateToProps = (state: AppState) => ({
    isAuthenticated: state.authentication.isAuthenticated && !!state.user.id
});
export const AgentConnect = connect(mapStateToProps, undefined, true)(AgentConnectApp);



export interface NavigationProps extends RouteComponentProps<any> {
    navigateBack: () => void;
    navigateTo: (route: string) => void;
    navigateToWithoutAddingToHistory: (route: string) => void;
    updateDispositionReturnPath: (returnPath: string) => void;
}

export interface RouteProps {
    path: string;
    component?: React.ComponentType | ConnectedComponent<any, any> | LazyExoticComponent<any>;
    permissions?: ApiPermission[];
    additionalPermission?: () => boolean;
    childRoute?: boolean;
    hasChildren?: boolean;
}

const enforceRouteMap = makeEnforceMapType<RouteProps>();
export const navRoutes = enforceRouteMap({
    emailAutomation: {
        path: `/emailAutomation`,
        component: EmailAutomationContainer,
        permissions: [`pManageAutomatedEmails`],
    },
    smsTemplateManagement: {
        path: `/smstemplate/management`,
        component: SmsTemplateManagementPage,
        permissions: [`pSmsTemplateCreate`],
    },
    smsTemplatePreview: {
        path: `/smstemplate/:templateState/:templateId`,
        component: SmsTemplatePage,
        permissions: [`pSmsTemplateCreate`],
    },
    templateManagement: {
        path: `/template/management`,
        component: TemplateManagementContainer,
        permissions: [`pContactRead`],
    },
    templateContent: {
        path: `/template/content/${SN.TemplateId}/${SN.EditMode}`,
        component: TemplateContentContainer,
        permissions: [`pContactRead`],
    },
    templatePreview: {
        path: `/template/preview/${SN.TemplateId}/${SN.From}/${SN.EmailHistoryId}`,
        component: TemplatePreviewContainer,
        permissions: [`pContactRead`],
    },
    templateSelect: {
        path: `/template/select`,
        component: TemplateSelectContainer,
        permissions: [`pContactRead`],
    },
    leadDashboard: {
        path: `/lead/dashboard`,
        component: LeadDashboardPageContainer,
        permissions: [`pLeadRead`],
    },
    leadCircle: {
        path: `/lead/circle`,
        component: LeadCirclePageContainer,
        permissions: [`pLeadRead`],
    },
    leadList: {
        path: `/lead/list`,
        component: LeadListPageContainer,
        permissions: [`pLeadRead`],
    },
    household: {
        path: `/household/${SN.HouseholdId}`,
        component: HouseholdPageContainer,
        permissions: [`pHouseholdRead`],
    },
    contactDetail: {
        path: `/household/${SN.HouseholdId}/contact/${SN.ContactId}`,
        component: ContactDetailsContainer,
        permissions: [`pContactRead`],
    },
    contactRedirect: {
        path: `/contact/${SN.ContactId}/household`,
        component: ContactRedirectContainer,
        permissions: [`pContactRead`],
    },
    contactUpsert: {
        path: `/household/${SN.HouseholdId}/contact/${SN.ContactId}/upsert`,
        component: UpsertContactContainer,
        permissions: [`pContactCreate`, `pContactUpdate`],
    },
    leadDetail: {
        path: `/household/${SN.HouseholdId}/lead/${SN.LeadId}/detail`,
        component: LeadDetailPageContainer,
        permissions: [`pLeadRead`],
    },
    leadDetailAdvSearch: {
        path: `/household/${SN.HouseholdId}/lead/${SN.LeadId}/detail/${SN.FromAdvSearch}`,
        component: LeadDetailPageContainer,
        permissions: [`pLeadRead`],
    },
    leadUpsert: {
        path: `/lead/${SN.LeadId}/household/${SN.HouseholdId}/upsert`,
        component: UpsertLeadContainer,
        permissions: [`pLeadCreate`],
    },
    leadUpsertPolicy: {
        path: `/lead/${SN.LeadId}/household/${SN.HouseholdId}/policy/${SN.PolicyId}/upsert`,
        component: UpsertLeadContainer,
        permissions: [`pLeadCreate`],
    },
    employerLeadUpsert: {
        path: `/lead/${SN.LeadId}/employer/${SN.EmployerId}/upsert`,
        component: UpsertLeadEmployerContainer,
        permissions: [`pLeadCreate`],
    },
    employerLeadUpsertPolicy: {
        path: `/lead/${SN.LeadId}/employer/${SN.EmployerId}/policy/${SN.PolicyId}/upsert/`,
        component: UpsertLeadEmployerContainer,
        permissions: [`pLeadCreate`],
    },
    employerUpsert: {
        path: `/employer/upsert/${SN.EmployerId}?`,
        component: UpsertEmployerContainer,
        permissions: [`pEmployerCreate`],
    },
    applicationUpsert: {
        path:
            `/employer/${SN.EmployerId}/household/${SN.HouseholdId}/application/${SN.ApplicationId}/upsert`,
        component: ApplicationUpsertPageContainer,
        permissions: [`pApplicationCreate`],
    },
    documents: {
        path: `/documents`,
        component: DocumentsPage,
        permissions: [`pDocumentRead`, `pHomeRead`],
    },
    finance: {
        path: `/finance`,
        component: FinanceContainer,
        permissions: [`pFinanceRead`, `pHomeRead`],
        additionalPermission: () => !isAgentRole([Strings.ADRoles.SubAgent]),
        hasChildren: true,
    },
    financeCash: { path: `/finance/cashDetail`, childRoute: true },
    financeBalances: { path: `/finance/balancesDetail`, childRoute: true },
    financeInvestments: { path: `/finance/investmentsDetail`, childRoute: true },
    performanceWithAgent: {
        path: `/performance/${SN.AgentCode}`,
        component: PerformanceContainer,
        permissions: [`pPerformanceRead`],
        hasChildren: true,
    },
    performance: {
        path: `/performance`,
        component: PerformanceContainer,
        permissions: [`pPerformanceRead`],
        hasChildren: true,
    },
    performanceActivities: { path: `/performance/activities`, childRoute: true },
    performanceLeads: { path: `/performance/leads`, childRoute: true },
    performanceProduction: { path: `/performance/production`, childRoute: true },
    performanceQuality: { path: `/performance/quality`, childRoute: true },
    teamview: {
        path: `/teamview`,
        component: TeamViewContainer,
        permissions: [`pTeamViewRead`],
    },
    home: {
        path: `/`,
        component: HomePageContainer,
        permissions: [`pHomeRead`],
    },
    profile: {
        path: `/profile`,
        component: ProfileLandingContainer,
        permissions: [`pProfileRead`, `pHomeRead`],
        hasChildren: true,
    },
    agentProfile: {
        path: `/agentProfile/${SN.AgentCode}`,
        component: AgentProfileLandingContainer,
        permissions: [`pAgentProfileRead`, `pHomeRead`],
        hasChildren: true,
    },
    agentHierarchy: {
        path: `/agentProfile/${SN.AgentCode}/agentHierarchy`,
        childRoute: true,
        permissions: [`pProfileRead`, `pAgentProfileRead`],
    },
    aboutMe: {
        path: `/profile/aboutMe`,
        childRoute: true,
    },
    contractCompensation: {
        path: `/profile/contractCompensation`,
        childRoute: true,
    },
    manageSubagents: {
        path: `/profile/manage-subagents`,
        childRoute: true,
    },
    manageReshopping: {
        path: `/profile/manage-reshopping`,
        childRoute: true,
    },
    myHierarchy: {
        path: `/profile/aboutMe/myHierarchy`,
        childRoute: true,
    },
    settings: {
        path: `/settings`,
        component: SettingsContainer,
        hasChildren: true,
        permissions: [`pSettingsRead`, `pSettingsUpdate`],
    },
    settingsNotifications: {
        path: `/settings/notifications`,
        childRoute: true,
    },
    settingsDesktopCall: {
        path: `/settings/web`,
        childRoute: true,
    },
    settingsClickToCallCallerId: {
        path: `/settings/caller-id`,
        childRoute: true,
        permissions: [`pClickToCall`],
    },
    settingsAuthentication: {
        path: `/settings/authentication`,
        childRoute: true,
    },
    settingsAppSupport: {
        path: `/settings/app-support`,
        childRoute: true,
    },
    googleCalendarSync: {
        path: `/settings/calendar`,
        childRoute: true,
    },
    activityCreate: {
        path: `/activity/upsert/`,
        component: UpsertActivityContainer,
        permissions: [`pActivityCreate`],
    },
    activityEdit: {
        path: `/activity/upsert/${SN.ActivityId}/${SN.EmployerId}/${SN.HouseholdId}?`,
        component: UpsertActivityContainer,
        permissions: [`pActivityUpdate`],
    },
    appointmentCreate: {
        path: `/appointment/upsert/${SN.EmployerId}/${SN.HouseholdId}?`,
        component: UpsertActivityContainer,
        permissions: [`pActivityCreate`],
    },
    appointmentEdit: {
        path:
            `/appointment/upsert/${SN.ActivityId}/${SN.Reschedule}/${SN.EmployerId}/${SN.HouseholdId}?`,
        component: UpsertActivityContainer,
        permissions: [`pActivityUpdate`],
    },
    phoneCreate: {
        path: `/phone/create/${SN.FollowUp}/${SN.EmployerId}/${SN.HouseholdId}?`,
        component: UpsertActivityContainer,
        permissions: [`pActivityCreate`],
    },
    phoneEdit: {
        path: `/phone/edit/${SN.ActivityId}/${SN.Reschedule}/${SN.EmployerId}/${SN.HouseholdId}?`,
        component: UpsertActivityContainer,
        permissions: [`pActivityUpdate`],
    },
    activities: {
        path: `/activities`,
        component: ActivitiesPageContainer,
        permissions: [`pActivityRead`],
    },
    taskDetail: {
        path: `/activities/task/detail/${SN.ActivityId}`,
        component: ActivityDetailContainer,
        permissions: [`pActivityRead`],
    },
    phoneDetail: {
        path: `/activities/phone/detail/${SN.ActivityId}`,
        component: ActivityDetailContainer,
        permissions: [`pActivityRead`],
    },
    appointmentDetail: {
        path: `/activities/appointment/detail/${SN.ActivityId}`,
        component: ActivityDetailContainer,
        permissions: [`pActivityRead`],
    },
    activityDetail: {
        path: `/activities/detail/${SN.ActivityId}`,
        component: ActivityDetailContainer,
        permissions: [`pActivityRead`],
    },
    quoteActivityDetail: {
        path: `/quoteActivity/${SN.QuoteActivityId}`,
        component: QuoteActivityDetailContainer,
        permissions: [`pLeadRead`]
    },
    tools: {
        path: `/tools`,
        component: ToolsPageContainer,
        permissions: [`pToolRead`, `pHomeRead`],
    },
    notificationAdmin: {
        path: `/notificationAdmin`,
        component: NavigationAdminPageContainer,
        permissions: [`pNotificationCreate`],
    },
    notificationList: {
        path: `/notifications`,
        component: NotificationsPageContainer,
        permissions: [`pNotificationRead`, `pHomeRead`],
    },
    battles: {
        path: `/battles`,
        component: BattlesContainer,
        permissions: [`pBattleRead`, `pHomeRead`],
    },
    battle: {
        path: `/battles/${SN.BattleId}`,
        component: BattleContainer,
        permissions: [`pBattleRead`, `pHomeRead`],
    },
    createBattle: {
        path: `/battles/${SN.BattleId}/upsert/${SN.Rematch}`,
        component: CreateBattleComponent,
        permissions: [`pBattleCreate`],
    },
    adminLanding: {
        path: `/adminLanding`,
        component: AdminLandingContainer,
        permissions: [`pAdminRead`],
        hasChildren: true,
    },
    adminNotificationList: {
        path: `/adminLanding/adminNotificationList`,
        childRoute: true,
    },
    adminNotificationDetail: {
        path: `/adminNotificationDetail/${SN.NotificationId}/${SN.UserId}`,
        component: AdminNotificationDetailContainer,
        permissions: [`pAdminNotificationRead`],
    },
    adminNotificationEdit: {
        path: `/adminNotificationEdit/${SN.NotificationId}/${SN.UserId}`,
        component: NotificationEditPageContainer,
        permissions: [`pNotificationCreate`, `pNotificationUpdate`],
    },
    notificationListDetail: {
        path: `/notificationListDetail/${SN.NotificationId}`,
        component: NotificationListDetailContainer,
        permissions: [`pNotificationRead`],
    },
    helpSupport: {
        path: `/helpsupport`,
        component: HelpSupportContainer,
        permissions: [`pHomeRead`],
    },
    filteredNotifications: {
        path: `/notificationsFiltered/${SN.CategorySelector}`,
        component: NotificationsPageContainer,
        permissions: [`pNotificationRead`],
    },
    urgentNotifications: {
        path: `/notificationsUrgent`,
        component: NotificationsPageContainer,
        permissions: [`pNotificationRead`],
    },
    agentSearch: {
        path: `/agentSearch`,
        component: AgentSearchPageContainer,
        permissions: [`pAgentSearchRead`, `pHomeRead`],
    },
    import: {
        path: `/import`,
        component: ImportPageContainer,
        permissions: [`pLeadCreate`],
    },
    introVideo: {
        path: `/introVideo`,
        component: IntroVideoContainer,
        permissions: [`pHomeRead`],
    },
    welcome: { path: `/welcome`, component: WelcomeContainer },
    termsAndConditions: {
        path: `/termsAndConditions`,
        component: TermsAndConditionsContainer,
        permissions: [`pUserAcceptTerms`],
    },
    impersonation: {
        path: `/impersonation`,
        component: ImpersonationPageContainer,
        permissions: [`pImpersonate`],
    },
    companyDNC: {
        path: `/company-dnc`,
        component: ManageCompanyDNCPage,
        permissions: [`pCompanyDNC`],
    },
    accountManagement: {
        path: `/account-management`,
        component: AccountManagementContainer,
        permissions: [`pAccountManagement`],
    },
    accountManagementUpsert: {
        path: `/account-management/upsert`,
        component: AccountUpsertContainer,
        permissions: [`pAccountManagement`],
    },
    productListPage: {
        path: `/product/list`,
        component: ProductListPageContainer,
        permissions: [`pApplicationRead`, `pPolicyRead`],
    },
    productDetail: {
        path: `/product/${SN.ProductId}`,
        component: ProductDetailPageContainer,
        permissions: [`pApplicationRead`, `pPolicyRead`],
    },
    employer: {
        path: `/employer/${SN.EmployerId}`,
        component: EmployerPageContainer,
        permissions: [`pEmployerRead`],
    },
    employeeUpsert: {
        path: `/employer/${SN.EmployerId}/contact/${SN.ContactId}/upsert`,
        component: UpsertContactContainer,
        permissions: [`pContactCreate`, `pContactUpdate`],
    },
    advancedSearch: {
        path: `/advancedSearch/:householdId?/:index?`,
        component: AdvancedSearchPageContainer,
        permissions: [`pAdvancedSearch`],
    },
    clientConnect: {
        path: `/client-connect`,
        component: ClientConnectContainer,
        permissions: [`pClientConnect`],
    },
    clientConnectUsage: {
        path: `/client-connect/usage`,
        component: ClientConnectActivityContainer,
        permissions: [`pClientConnect`],
    },
    leadSearch: {
        path: `/leadsearch`,
        component: LeadSearchContainer,
        permissions: [`pLeadRead`],
    },
    podcastList: {
        path: `/podcast/list/`,
        component: PodcastListContainer,
        permissions: [`pPodcastRead`],
    },
    podcastUpsert: {
        path: `/podcast/upsert/${SN.PodcastId}`,
        component: UpsertPodcastContainer,
        permissions: [`pPodcastCreate`],
    },
    podcastDetail: {
        path: `/podcast/detail/${SN.PodcastId}`,
        component: PodcastDetailContainer,
        permissions: [`pPodcastRead`],
    },
    factFinder: {
        path: `/factfinder/search`,
        component: () => <span />,
        permissions: [`pFactFinder`],
    },
    factFinderForm: {
        path: `/factfinder/form/:id/:householdId`,
        component: () => <span />,
        permissions: [`pFactFinder`],
    },
    reportBuilder: {
        path: `/rise-reports`,
        component: () => <span />,
        permissions: [`pReportBuilder`],
    },
    openConnectureByContactId: {
        path: `/openconnecture/contact/${SN.ContactId}`,
        component: ConnecturePageComponent,
        permissions: [`pContactUpdate`, `pConnecture`]
    },
    openConnecture: {
        path: `/openconnecture`,
        component: ConnecturePageComponent,
        permissions: [`pContactUpdate`, `pConnecture`]
    },
    license: {
        path: `/licenses`,
        component: LicenseAppointmentContainer,
        permissions: [`pLicensesAndAppointmentsRead`],
    },
    appointment: {
        path: `/appointments`,
        component: LicenseAppointmentContainer,
        permissions: [`pLicensesAndAppointmentsRead`],
    },
    commissionStatement: {
        path: `/commission-statement`,
        component: CommissionStatementContainer,
        permissions: [`pFinanceRead`],
    },
});

export const getNavRouteByKeySafe = makeMakeGetByKey<RouteProps>()(navRoutes);
export const getNavRouteByKey = createOpenGetByKey(getNavRouteByKeySafe);