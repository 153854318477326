import React, { useEffect } from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { navRoutes } from '../nav/Routes';
import { NotificationItem } from '../../components/notifications/notification_item';
import { GetAllNotifications, MarkNotificationsAsRead } from '../../actions/notification_actions';
import { Button } from '@material-ui/core';
import { Notification } from '../../containers/notifications_page/notifications_page';
import NotificationsSvg from '../svgs/icons/notifications';
import { themePalette } from '../../utilities/branding';
import { Strings } from '../../assets/common/strings';
import { Dispatch } from '@reduxjs/toolkit';
import { AppState } from '../../reducers';
import LoadingList from '../utility/loading_list';
import { getWidgetNotifications } from '../../selectors/notifications_selectors';
import { Widget } from './base_widget';
import { EmptyMessage } from '../utility/empty_message';
import { useNavigateTo, useNavigationProps } from '../../utilities/hooks';

interface StateProps {
	userID: string;
	newNotifications: Notification[];
	loading: boolean;
}
interface DispatchProps {
	fetchAllNotifications: (userID: string) => void;
	markNotificationsAsRead: (notificationIds: string[], userId: string) => void;
}
type Props = StateProps & DispatchProps;
const NotificationsWidget: React.FC<Props> = (props) => {

	const { userID, fetchAllNotifications, markNotificationsAsRead, loading, newNotifications } = props;
	const { navigateTo } = useNavigationProps();
	const navToNotificationsList = useNavigateTo(navRoutes.notificationList.path);

	useEffect(() => {
		fetchAllNotifications(userID);
	}, []);

	const navigateToDetail = (notification: Notification) => () => {

		if (!notification.isRead) {
			markNotificationsAsRead([notification.id], userID);
		}

		const detailURL = navRoutes.notificationListDetail.path.replace(
			Strings.Navigation.NotificationId,
			notification.id
		);
		navigateTo(detailURL);
	};

	const renderWidgetBody = () => {
		return loading && newNotifications.length === 0
			? <LoadingList size={2} />
			: newNotifications?.length
				? newNotifications.map(notification => (
					<NotificationItem
						key={notification.id}
						notification={notification}
						onClick={navigateToDetail(notification)}
						singleLine
					/>
				))
				: <EmptyMessage text="No new notifications available"/>;
	}

	return (
		<Widget
			title={Strings.WidgetTitles.Notifications}
			avatar={<NotificationsSvg width={30} height={30} fill={themePalette.menuSvgNotifications} />}
			actionButtons={[
				<Button onClick={navToNotificationsList}>
					View All
				</Button>
			]}
			onClick={navToNotificationsList}
		>
			{renderWidgetBody()}
		</Widget>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	userID: state.user.impersonatingId || state.user.id,
	newNotifications: getWidgetNotifications(state),
	loading: state.notifications.newLoading
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	fetchAllNotifications: (userID: string) => dispatch(GetAllNotifications.started(userID)),
	markNotificationsAsRead: (notificationIds: string[], userId: string) => dispatch(MarkNotificationsAsRead.started({ notificationIds, userId })),
});

export const NotificationsWidgetContainer = connect(mapStateToProps, mapDispatchToProps, true)(NotificationsWidget);
