import React, { useEffect, useState } from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { compose, Dispatch } from 'redux';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { NavigationProps, navRoutes } from '../../components/nav/Routes';
import { navigateBack, navigateTo } from '../../actions/navigation_actions';
import {
	MenuItem,
	Button,
	Grid,
	TextField,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
	Paper,
	Avatar,
} from '@material-ui/core';
import moment from 'moment';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { FetchClientConnectSettings, getClientConnectUsageActivities } from '../../actions/client_connect_actions';
import { DatePicker } from '../../components/redux_form_material';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { themeLinks } from '../../utilities/branding';
import { AppState } from '../../reducers';
import { ListFilter } from '../../components/filter/list_filter';
import { ClientConnectActivity, ClientConnectActivityType, ClientConnectActivityTypesList, getClientConnectItemAvatar } from '../../utilities/shared';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { EmptyMessage } from '../../components/utility/empty_message';
import { SimpleListItem } from '../../components/utility/simple_list_item';
import { normalizePhone } from '../../utilities/formatting/data_normalizations';
import { displayDate } from '../../utilities/date_util';
import { useListItemStyles } from '../../utilities/hooks/styles';

const initialFilter: FilterState = {
	user: 'All Users',
	activity: 'All Activities',
	date: undefined
};
const applyDefaultSort = (a: ClientConnectActivity, b: ClientConnectActivity): number => {
	const aDate = moment(a.Time);
	const bDate = moment(b.Time);

	if (aDate.isBefore(bDate)) {
		return 1;
	} else if (aDate.isAfter(bDate)) {
		return -1;
	} else {
		return 0;
	}
};
const uniques = (value: string, index: number, self: string[]) => self.indexOf(value) === index;

interface StateProps {
	isActivated: boolean;
	activities: ClientConnectActivity[];
	loading: boolean;
}
interface DispatchProps {
	navigateBack(): void;
	navigateTo(route: string): void;
	fetchClientConnectSettings: () => void;
	getClientConnectUsageActivities: () => void;
}
interface FilterState {
	user: string;
	activity: string;
	date?: Date;
}

type Props = NavigationProps & StateProps & DispatchProps;
const CCActivityList: React.FC<Props> = (props) => {
	
	const { 
		activities, isActivated, loading,
		fetchClientConnectSettings, getClientConnectUsageActivities,
		navigateBack, navigateTo
	} = props;
	const [filter, setFilter] = useState<FilterState>(initialFilter);
	const styles = useListItemStyles();

	useEffect(() => {
		if (!isActivated) {
			fetchClientConnectSettings();
		}
		getClientConnectUsageActivities();
	}, []);

	const handleFilterActivityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilter(filterItems => ({ ...filterItems, activity: event.target.value }));
	};

	const handleFilterNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilter(filterItems => ({ ...filterItems, user: event.target.value }));
	};

	const handleFilterDateChange = (date: Date) => {
		setFilter(filterItems => ({ ...filterItems, date }));
	};

	const handleResetForm = () => {
		setFilter(initialFilter);
	};

	const applyFilters = () => {
		const allActivities = filter.activity === 'All Activities';
		const allUsers = filter.user === 'All Users';

		let activityType: string = filter.activity;
		if (filter.activity === ClientConnectActivityType.AppointmentRequested) {
			// Since combining Appointment and Quote types, we don't want
			// the filter to skip over old data
			activityType = 'Appointment Requested';
		}

		const filteredUsages = activities.filter((activity: ClientConnectActivity) => {
			const activityMatch = activity.ActivityType === activityType;
			const userMatch = activity.Name === filter.user;
			const dateMatch = filter.date ? moment(activity.Time).isSame(filter.date, 'day') : true;
			return (
				(allActivities || activityMatch) && (allUsers || userMatch) && dateMatch
			);
		}).sort(applyDefaultSort);

		return filteredUsages;
	}

	const renderFilter = (filtered: ClientConnectActivity[]) => {
		const cardSubtitle = `${filter.user} - ${filter.activity}`;
		const drawerSubtitle = `Displaying ${filtered.length} result(s)`;

		const uniqueNames = ['All Users'].concat(
			activities.map(activity => activity.Name)
				.filter(uniques)
		);

		const dateValue = moment(filter.date);
		const minDate = moment()
			.subtract(1, 'month')
			.startOf('day');
		const maxDate = moment().endOf('day');
		const dateCheck = dateValue.isAfter(minDate) && dateValue.isBefore(maxDate);

		return (
			<ListFilter
				drawerTitle={'Activities'}
				cardSubtitle={cardSubtitle}
				drawerSubtitle={drawerSubtitle}
				searchButtonText={'Filter'}
				onReset={handleResetForm}
				onSearch={() => {}}
			>
				<Grid container>
					<Grid item xs={12}>
						<TextField
							select
							label="Activity"
							value={filter.activity}
							onChange={handleFilterActivityChange}
							fullWidth
						>
							{ClientConnectActivityTypesList.map(
								(activityTypeString: string, index: number) => {
									return (
										<MenuItem key={index} value={activityTypeString}>
											{activityTypeString}
										</MenuItem>
									);
								}
							)}
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<TextField
							select
							label="Name"
							onChange={handleFilterNameChange}
							value={filter.user}
							fullWidth
						>
							{uniqueNames.map((name: string, index: number) => {
								return (
									<MenuItem key={index} value={name}>
										{name}
									</MenuItem>
								);
							})}
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<DatePicker
							label="Date Filter"
							input={{
								onChange: handleFilterDateChange,
								value: dateValue.toDate(),
							}}
							fullWidth
							minDate={minDate.toDate()}
							maxDate={maxDate.toDate()}
							meta={
								{
									error: !dateCheck ? 'Invalid Date' : undefined,
								} as any
							}
						/>
					</Grid>
				</Grid>
			</ListFilter>
		);
	};


	if (!isActivated) {
		return (
			<Dialog open={true}>
				<DialogContent>
					<DialogContentText>
						Please configure your ClientConnect in your About Me section.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color="secondary"
						onClick={navigateBack}
					>
						Back
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							navigateTo(navRoutes.aboutMe.path);
						}}
					>
						Go to About Me
					</Button>
				</DialogActions>
			</Dialog>
		);
	}

	let filteredUsages = applyFilters();

	return (
		<BasePageContainer
			topComponent={
				<HeaderBarComponent
					title="Client Activity Report"
					rightButtons={
						<MoreMenu children={[createHelpAction(themeLinks.helpLinkClientConnect)]} />
					}
					customContent={renderFilter(filteredUsages)}
					isLoading={loading}
				/>
			}
		>
			<DesktopPadding>
				{!filteredUsages.length 
					? (<EmptyMessage text="No activity to display" />) 
					: (
						<Paper elevation={0}>
							{(filteredUsages || []).map(
								(activity: ClientConnectActivity, index: number) =>
									<SimpleListItem
										icon={<Avatar className={styles.small} src={getClientConnectItemAvatar(activity.ActivityType)} />}
										title={`${activity.ActivityType} - ${activity.Name} - ${normalizePhone(activity.Phone)}`}
										subtitle={activity.Description}
										rightText={displayDate(activity.Time)}
									/>
							)}
						</Paper>
					)}
				</DesktopPadding>
		</BasePageContainer>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	isActivated: state.clientConnect.settings.isActivated,
	activities: state.clientConnect.activities,
	loading: state.clientConnect.loading,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	navigateBack: () => dispatch(navigateBack()),
	navigateTo: (route: string) => dispatch(navigateTo(route)),
	fetchClientConnectSettings: () => dispatch(FetchClientConnectSettings()),
	getClientConnectUsageActivities: () => dispatch(getClientConnectUsageActivities.started())
});

export const ClientConnectActivityContainer = compose(
	connect(mapStateToProps, mapDispatchToProps, true)
)(CCActivityList);
