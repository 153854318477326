import { SmsSequenceState, smsSequenceReducer } from './sequences/sms_sequence_reducer';
import {
	OwnershipHistoryState,
	ownershipHistoryReducer,
} from './ownership_history_reducer';
import { AttachmentState, attachmentReducer } from './attachment_reducer';
import { NoteState, noteReducer } from './note_reducer';
import { SnackbarState, snackbarReducer } from './snackbar_reducer';
import { accessPageReducer, AccessPageState } from './AccessPageReducer';
import { reducer as formReducer, FormStateMap } from 'redux-form';
import { navigationReducer, NavigationState } from './navigation_reducer';
import { FinanceState, financeReducer } from './finance_reducer';
import {
	AuthenticationState,
	authenticationReducer,
} from './authentication_reducer';
import { userReducer, User } from './user_reducer';
import { agentReducer, AgentState } from './agent_reducer';
import { battlesReducer, BattlesState } from './battles_reducer';
import { leadRoutesReducer, LeadRoutes } from './lead_routes_reducer';
import { leadReducer, Leads } from './LeadReducer';
import { NotificationState, notificationReducer } from './notification_reducer';
import { ActivityState, activityReducer } from './activity_reducer';
import { Contacts, contactReducer } from './ContactReducer';
import {
	ClientConnectState,
	clientConnectReducer,
} from './ClientConnectReducer';
import { LayoutState, layoutReducer } from './LayoutReducer';
import { LoggingState, loggingReducer } from './logging_reducer';
import { PolicyState, policyReducer } from './policy_reducer';
import { ApplicationState, applicationReducer } from './application_reducer';
import { ProductFilters, productFilterReducer } from './product_filter_reducer';
import {
	ActivityFilters,
	activityFilterReducer,
} from './activity_filter_reducer';
import { EmployerState, employerReducer } from './employer_reducer';
import { LeadFilters, leadFilterReducer } from './LeadFilterReducer';
import { fileReducer, FileState } from './file_reducer';
import {
	TemplateManagerState,
	templateManagerStateReducer,
} from './template_reducer';
import { householdReducer, HouseholdMetaDataState } from './household_reducer';
import {
	advancedSearchReducer,
	AdvancedSearchState,
} from './advanced_search_reducer';
import {
	infiniteScrollReducer,
	InfiniteScrollState,
} from './infinite_scroll_reducer';
import {
	emailAutomationReducer,
	EmailAutomationState,
} from './email_automation_reducer';
import { dncPhoneReducer, DNCPhoneState } from './dnc_phone_reducer';
import {
	geographicSearchReducer,
	GeographicSearchState,
} from './geographic_search_reducer';
import {
	EmailHistorySummaryState,
	emailHistorySummaryReducer,
} from './email_summary_reducer';
import {
	lookupReducer, LookupTypes,
} from './lookup_reducer';
import { TeamViewState, teamviewReducer } from './teamview_reducer';
import { PerformanceState, performanceReducer } from './performance_reducer';
import { LeadSearchState, leadSearchReducer } from './lead_search_reducer';
import { ProductState, productReducer } from './product_reducer';
import { AgentSearchState, agentSearchReducer } from './agent_search_reducer';
import { ToolsState, toolsReducer } from './tools_reducer';
import { DocumentState, documentsReducer } from './document_reducer';
import { dialogsReducer, DialogsState, LeadInfoDialogState, leadInfoDialogReducer, LeadContactDialogState, leadInfoContactReducer, prescriptionSearchReducer, PrescriptionSearchDialogState, providerSearchReducer, ProviderSearchDialogState } from './dialogs_reducer';
import { Action, combineReducers } from 'redux';
import { ImpersonationState, impersonationReducer } from './impersonation_reducer';
import { AdminState, adminReducer } from './admin_reducer';
import { DedupeState, dedupeReducer } from './dedupe_reducer';
import { DataTableState, dataTableReducer } from './data_table_reducer';
import { SmsTemplateState, smsTemplateReducer } from './sms_template_reducer';
import { FilterTableState, tableReducer } from '../reducers/table_reducer';
import { QuoteActivityState, quoteActivityReducer } from './quote_activity_reducer';
import { FilterDrawerState, filterDrawerReducer } from '../components/Layout/filter/filter_drawer';
import { callSequenceReducer, CallSequenceState } from './sequences/call_sequence_reducer';
import { ClickToCallState, clickToCallReducer } from './click_to_call_reducer';
import { podcastReducer, PodcastState } from './podcast_reducer';
import { productivityReducer, ProductivityState } from './productivity_reducer';
import { reshopReducer, ReshopState } from './reshop_reducer';
import { licenseAppointmentReducer, LicenseAppointmentState } from './license_appointment_reducer';
import { AgentCommissionStatementState, commissionStatementReducer } from './commission_statement_reducer';
import { calendarReducer, CalendarState } from './calendar_reducer';
// import { staticLookupReducer, StaticLookupState } from '../utilities/lookup/static_lookups';

export interface GlobalState {
	accessPage: AccessPageState;
	activity: ActivityState;
	activityFilters: ActivityFilters;
	admin: AdminState;
	advancedSearch: AdvancedSearchState;
	agent: AgentState;
	agentSearch: AgentSearchState;
	application: ApplicationState;
	attachment: AttachmentState;
	authentication: AuthenticationState;
	battles: BattlesState;
	calendar: CalendarState;
	callSequence: CallSequenceState;
	clickToCall: ClickToCallState;
	clientConnect: ClientConnectState;
	contact: Contacts;
	dialogs: DialogsState;
	dncPhone: DNCPhoneState;
	documents: DocumentState;
	emailAutomation: EmailAutomationState;
	emailHistorySummary: EmailHistorySummaryState;
	employer: EmployerState;
	form: FormStateMap;
	file: FileState;
	filterDrawers: FilterDrawerState;
	finance: FinanceState;
	geographicSearch: GeographicSearchState;
	householdMetaData: HouseholdMetaDataState;
	impersonation: ImpersonationState;
	infiniteScroll: InfiniteScrollState;
	layout: LayoutState;
	lead: Leads;
	leadFilters: LeadFilters;
	leadInfoDialog: LeadInfoDialogState;
	leadSearchDialog: LeadContactDialogState;
	leadRoutes: LeadRoutes;
	leadSearch: LeadSearchState;
	logging: LoggingState;
	lookup: LookupTypes;
	// lookup2: StaticLookupState;
	navigation: NavigationState;
	note: NoteState;
	notifications: NotificationState;
	ownershipHistory: OwnershipHistoryState;
	teamview: TeamViewState;
	performance: PerformanceState;
	policy: PolicyState;
	prescriptionSearchDialog: PrescriptionSearchDialogState;
	product: ProductState;
	productivity: ProductivityState;
	productFilters: ProductFilters;
	providerSearchDialog: ProviderSearchDialogState;
	reshop: ReshopState;
	snackbar: SnackbarState;
	smsSequence: SmsSequenceState;
	smsTemplate: SmsTemplateState;
	templateManagerState: TemplateManagerState;
	tables: FilterTableState;
	tools: ToolsState;
	user: User;
	dedupe: DedupeState;
	dataTables: DataTableState;
	quoteActivity: QuoteActivityState
	podcast: PodcastState;
	licenseAppointment: LicenseAppointmentState;
	commissionStatement: AgentCommissionStatementState
}

export const appReducer = combineReducers({
	accessPage: accessPageReducer,
	activity: activityReducer,
	activityFilters: activityFilterReducer,
	admin: adminReducer,
	advancedSearch: advancedSearchReducer,
	agent: agentReducer,
	agentSearch: agentSearchReducer,
	application: applicationReducer,
	attachment: attachmentReducer,
	authentication: authenticationReducer,
	battles: battlesReducer,
	calendar: calendarReducer,
	callSequence: callSequenceReducer,
	clickToCall: clickToCallReducer,
	clientConnect: clientConnectReducer,
	contact: contactReducer,
	dialogs: dialogsReducer,
	dncPhone: dncPhoneReducer,
	documents: documentsReducer,
	emailAutomation: emailAutomationReducer,
	emailHistorySummary: emailHistorySummaryReducer,
	employer: employerReducer,
	file: fileReducer,
	filterDrawers: filterDrawerReducer,
	finance: financeReducer,
	form: formReducer,
	geographicSearch: geographicSearchReducer,
	householdMetaData: householdReducer,
	impersonation: impersonationReducer,
	infiniteScroll: infiniteScrollReducer,
	layout: layoutReducer,
	lead: leadReducer,
	leadFilters: leadFilterReducer,
    leadInfoDialog: leadInfoDialogReducer,
    leadSearchDialog: leadInfoContactReducer,
	leadRoutes: leadRoutesReducer,
	leadSearch: leadSearchReducer,
	logging: loggingReducer,
	lookup: lookupReducer,
	// staticLookup: staticLookupReducer,
	navigation: navigationReducer,
	note: noteReducer,
	notifications: notificationReducer,
	ownershipHistory: ownershipHistoryReducer,
	teamview: teamviewReducer,
	performance: performanceReducer,
	policy: policyReducer,
	prescriptionSearchDialog: prescriptionSearchReducer,
	product: productReducer,
	productivity: productivityReducer,
	productFilters: productFilterReducer,
	providerSearchDialog: providerSearchReducer,
	reshop: reshopReducer,
	smsTemplate: smsTemplateReducer,
	smsSequence: smsSequenceReducer,
	snackbar: snackbarReducer,
	tables: tableReducer,
	templateManagerState: templateManagerStateReducer,
	tools: toolsReducer,
	user: userReducer,
	dedupe: dedupeReducer,
	dataTables: dataTableReducer,
	quoteActivity: quoteActivityReducer,
	podcast: podcastReducer,
	licenseAppointment: licenseAppointmentReducer,
	commissionStatement: commissionStatementReducer
});

const emptyAction: Action = { type: '' };
export const reduxInitialState = appReducer(undefined as any, emptyAction);

export type AppState = GlobalState;
