import { all } from '@optum-uhone-hmkts/rise';
import { activitySagas } from './activity_sagas';
import { adminSagas } from './admin_sagas';
import { advancedSearchSagas } from './advanced_search_sagas';
import { agentSagas } from './agent_sagas';
import { agentSearchSagas } from './agent_search_sagas';
import { applicationSagas } from './application_sagas';
import { attachmentSagas } from './attachment_sagas';
import { authenticationSagas } from './authentication_sagas';
import { battleSagas } from './battle_sagas';
import { calendarSagas } from './calendar_sagas';
import { clientConnectSagas } from './clientConnect_sagas';
import { contactSagas } from './contact_sagas';
import { dedupeSagas } from './dedupe_sagas';
import { dncPhoneSagas } from './dnc_phone_saga';
import { documentSagas } from './document_sagas';
import { emailAutomationSagas } from './email_automation_sagas';
import { emailSummarySagas } from './email_summary_sagas';
import { employerSagas } from './employer_sagas';
import { fcmSagas } from './fcm_sagas';
import { fileSagas } from './file_sagas';
import { financeSagas } from './finance_sagas';
import { geographicSearchSagas } from './geographic_search_sagas';
import { householdSagas } from './household_sagas';
import { impersonationSagas } from './impersonation_sagas';
import { leadRouteSagas } from './lead_route_sagas';
import { leadSagas } from './lead_sagas';
import { leadSearchSagas } from './lead_search_sagas';
import { loggingSagas } from './logging_sagas';
import { lookupSagas } from './lookup_sagas';
import { noteSagas } from './note_sagas';
import { notificationSagas } from './notification_sagas';
import { teamviewSagas } from './teamview_sagas';
import { performanceSagas } from './performance_sagas';
import { policySagas } from './policy_sagas';
import { productSagas } from './product_sagas';
import { snackbarSagas } from './snackbar_sagas';
import { templateSagas } from './template_sagas';
import { toolsSagas } from './tools_sagas';
import { userSagas } from './user_sagas';
import { smsTemplateSagas } from './sms_template_sagas';
import { quoteActivitySagas } from './quote_activity_sagas';
import { smsSequenceSagas } from './sequence/sms_sequence_sagas';
import { callSequenceSagas } from './sequence/call_sequence_sagas';
import { clickToCallSagas } from './click_to_call_sagas';
import { spawn } from 'redux-saga/effects';
import { podcastSagas } from './podcast_sagas';
import { productivitySagas } from './productivity_sagas';
import { reshopSagas } from './reshop_sagas';
import { licenseAppointmentSaga } from './license_appointment_sagas';
import { commissionStatementSaga } from './commission_statement_sagas';

const allSagas = [
	activitySagas,
	adminSagas,
	advancedSearchSagas,
	agentSagas,
	agentSearchSagas,
	applicationSagas,
	attachmentSagas,
	authenticationSagas,
	battleSagas,
	calendarSagas,
	callSequenceSagas,
	clientConnectSagas,
	clickToCallSagas,
	contactSagas,
	dncPhoneSagas,
	documentSagas,
	emailAutomationSagas,
	emailSummarySagas,
	employerSagas,
	fcmSagas,
	fileSagas,
	financeSagas,
	geographicSearchSagas,
	householdSagas,
	impersonationSagas,
	leadRouteSagas,
	leadSagas,
	leadSearchSagas,
	loggingSagas,
	lookupSagas,
	noteSagas,
	notificationSagas,
	teamviewSagas,
	performanceSagas,
	policySagas,
	productSagas,
	reshopSagas,
	smsSequenceSagas,
	snackbarSagas,
	templateSagas,
	toolsSagas,
	userSagas,
	dedupeSagas,
	smsTemplateSagas,
	quoteActivitySagas,
	podcastSagas,
	productivitySagas,
	licenseAppointmentSaga,
	commissionStatementSaga
];

export function* rootSaga() {
	yield all(allSagas.map(spawn));
}
