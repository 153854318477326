import _ from 'lodash';
import {
	Button,
	Switch,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Avatar,
	Icon,
} from '@material-ui/core';
import React from 'react';
import { connect } from '@optum-uhone-hmkts/rise';
import { fingerprint_auth, pin_auth } from '../../utilities/auth';
import {
	HeaderBarComponent,
} from '../../components/Layout/HeaderBar';
import { NavigationProps } from '../../components/nav/Routes';
import { isMobileDevice, isIOS } from '../../utilities/is_mobile';
import { themePalette, themeLinks } from '../../utilities/branding';
import { AppState } from '../../reducers/index';
import { SecureStorage } from '../../index';
import { SettingsPasswordConfirmationComponent } from './settings_confirm_password';
import { getFormValues } from 'redux-form';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { navigateTo } from '../../actions/navigation_actions';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { Strings } from '../../assets/common/strings';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { Dispatch } from '@reduxjs/toolkit';
import { ToggleCard } from '../../components/higher_order_component/wrap_in_card';
import { SimpleListItem } from '../../components/utility/simple_list_item';

interface Props extends NavigationProps {
	touchIDEnabled: boolean;
	pinCodeEnabled: boolean;
	secureStorageAvailable: boolean;
	settingsPasswordFormValues: SettingsForm;
}
interface SettingsForm {
	password: string;
}

interface State {
	fingerprintAvailable: boolean;
	confirmationModelToggled: boolean;
	deviceStoredPassword: string | undefined;
	invalidPasswordMessage: string;
	passwordValidated: boolean;
	anchorEl?: HTMLElement;
	passwordSaved: boolean;
	displayDialog: boolean;
}

class SettingsAuthentication extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			fingerprintAvailable: false,
			confirmationModelToggled: false,
			deviceStoredPassword: undefined,
			invalidPasswordMessage: '',
			passwordValidated: false,
			passwordSaved: false,
			displayDialog: false,
		};
	}

	togglePinEnabled = (
		event: object | undefined,
		toggled: boolean,
		fromTouchID?: boolean
	) => {
		if (toggled) {
			pin_auth.hasPin().then((hasPin: boolean) => {
				if (hasPin) {
					pin_auth.setPinEnabled(true);
				} else {
					pin_auth.setting_pin = true;
					pin_auth.from_fingerprint = fromTouchID ? true : false;
					pin_auth.showPin();
				}
			});
		} else {
			fingerprint_auth.setFingerprintEnabled(false);
			pin_auth.setPinEnabled(false);
		}
	};

	changePinCode = () => {
		this.setState({ confirmationModelToggled: true });
	};

	componentWillMount() {
		fingerprint_auth.fingerprintAvailable().then((available: boolean) => {
			this.setState({ fingerprintAvailable: available });
		});

		this.getLoggedInPassword().then((password: string) => {
			this.setState({ deviceStoredPassword: password });
		});
	}

	getLoggedInPassword = () => {
		return new Promise((resolve, reject) => {
			if (SecureStorage) {
				SecureStorage.get(
					function (password: string) {
						resolve(password);
					},
					function (_error: any) {
						resolve(undefined);
					},
					Strings.Cordova.SecureStoragePasswordKey
				);
			} else {
				resolve(undefined);
			}
		});
	};

	confirmPassword = () => {
		const devicePassword = this.state.deviceStoredPassword;
		const formPassword = _.get(
			this.props,
			'settingsPasswordFormValues.password',
			undefined
		);
		const passwordValidated = this.isPasswordValidated(
			formPassword,
			devicePassword
		);

		if (passwordValidated && formPassword && devicePassword) {
			this.setState({ passwordValidated: true });
			setTimeout(() => {
				pin_auth.setting_pin = true;
				pin_auth.reset_pin = true;
				pin_auth.showPin();
				this.setState({
					confirmationModelToggled: false,
					deviceStoredPassword: '',
				});
			}, 600);
		}

		const invalidPassword =
			!passwordValidated && formPassword && devicePassword;

		if (invalidPassword) {
			this.setState({
				invalidPasswordMessage: 'Invalid password. Please try again.',
			});
		}
	};

	componentWillReceiveProps(nextProps: Props) {
		const previousPassword = _.get(
			this.props,
			'settingsPasswordFormValues.password',
			undefined
		);

		const nextPassword = _.get(
			nextProps,
			'settingsPasswordFormValues.password',
			undefined
		);

		if (previousPassword && nextPassword && previousPassword != nextPassword) {
			this.setState({ invalidPasswordMessage: '' });
		}
	}

	isPasswordValidated = (
		formPassword: string | undefined,
		devicePassword: string | undefined
	) => {
		return formPassword == devicePassword;
	};

	renderPasswordConfirmationDialog = () => {
		return (
			<Dialog open={this.state.confirmationModelToggled}>
				<DialogTitle>Confirm password to continue.</DialogTitle>
				<DialogContent>
					<SettingsPasswordConfirmationComponent
						initialValues={{ password: '' }}
						passwordValidated={this.state.passwordValidated}
					/>
					<span style={{ color: themePalette.error }}>
						{this.state.invalidPasswordMessage}
					</span>
				</DialogContent>
				<DialogActions>{this.renderActionButtons()}</DialogActions>
			</Dialog>
		);
	};

	renderActionButtons = () => {
		if (this.state.passwordValidated) {
			return [
				<Avatar style={this.avatarStyle}>
					<Icon>check_circle</Icon>
				</Avatar>,
			];
		}

		return [
			<Button
				key="cancel"
				color="secondary"
				onClick={() => {
					this.setState({
						confirmationModelToggled: false,
						passwordValidated: false,
					});
				}}
			>
				Cancel
			</Button>,
			<Button
				key="ok"
				variant="contained"
				color="primary"
				onClick={this.confirmPassword}
			>
				OK
			</Button>,
		];
	};

	toggleTouchIDEnabled = (event: object, toggled: boolean) => {
		if (toggled) {
			fingerprint_auth.forceFingerprint().then(didConfirm => {
				if (didConfirm) {
					if (this.props.pinCodeEnabled) {
						fingerprint_auth.setFingerprintEnabled(toggled);
					} else {
						pin_auth.hasPin().then((hasPin: boolean) => {
							if (hasPin) {
								pin_auth.setPinEnabled(true);
								fingerprint_auth.setFingerprintEnabled(true);
							} else {
								this.setState({
									displayDialog: true,
								});
							}
						});
					}
				}
			});
		} else {
			fingerprint_auth.setFingerprintEnabled(toggled);
		}
	};

	setPinThenEnableFingerprint = () => {
		this.togglePinEnabled(undefined, true, true);
		this.setState({
			displayDialog: false,
		});
	};

	cancelEnableFingerprint = () => {
		fingerprint_auth.setFingerprintEnabled(false);
		this.setState({
			displayDialog: false,
		});
	};

	render() {
		const { pinCodeEnabled, secureStorageAvailable, touchIDEnabled } = this.props;
		const { fingerprintAvailable } = this.state;

		return (
			<BasePageContainer
				topComponent={
					<HeaderBarComponent
						title="Settings"
						rightButtons={
							<MoreMenu
								children={[createHelpAction(themeLinks.helpLinkSettings)]}
							/>
						}
					/>
				}
			>
				<Dialog open={this.state.displayDialog}>
					<DialogTitle>Enable Pin</DialogTitle>
					<DialogContent>
						<DialogContentText>
							You must first set a pin code to enable fingerprint login.
							Continue?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							color="secondary"
							onClick={this.cancelEnableFingerprint.bind(this)}
						>
							Cancel
						</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={this.setPinThenEnableFingerprint.bind(this)}
						>
							Continue
						</Button>
					</DialogActions>
				</Dialog>
				{this.renderPasswordConfirmationDialog()}

				<PersistentNotifications />
				<DesktopPadding>
					<ToggleCard
						title={`${isIOS ? 'TouchId' : 'Fingerprint'} & Pin`}
						noPadding
					>
						<SimpleListItem
							title={'Pin Code'}
							subtitle={
								isMobileDevice
									? (
										<Button
											onClick={this.changePinCode}
											disabled={!pinCodeEnabled || !secureStorageAvailable}
										>
											{secureStorageAvailable
												? 'Change Pin Code'
												: 'Pin Code not available without lock screen'											
											}
										</Button>
									)
									: 'Pin Codes are not available on web'
							}
							secondaryAction={
								<Switch
									color="primary"
									onChange={this.togglePinEnabled}
									checked={pinCodeEnabled}
									disabled={!isMobileDevice || !secureStorageAvailable}
								/>
							}
						/>
						<SimpleListItem
							title={isIOS ? 'TouchID' : 'Fingerprint'}
							subtitle={
								this.state.fingerprintAvailable
									? secureStorageAvailable
										? ''
										: 'Fingerprint not available without lock screen'
									: 'Your device does not have a fingerprint reader available'
							}
							secondaryAction={
								<Switch
									color="primary"
									onChange={this.toggleTouchIDEnabled}
									checked={touchIDEnabled}
									disabled={!fingerprintAvailable || !secureStorageAvailable}
								/>
							}
						/>
					</ToggleCard>
				</DesktopPadding>
			</BasePageContainer>
		);
	}

	public avatarStyle: React.CSSProperties = {
		alignSelf: 'center',
		backgroundColor: themePalette.selected_avatar,
	};
}

const mapStateToProps = (state: AppState): Partial<Props> => ({
	touchIDEnabled: state.authentication.touchIDEnabled,
	pinCodeEnabled: state.authentication.pinCodeEnabled,
	secureStorageAvailable: state.authentication.secureStorageAvailable,
	settingsPasswordFormValues: getFormValues('SettingsPasswordConfirmationForm')(state),
});
const mapDispatchToProps = (dispatch: Dispatch): Partial<Props> => ({
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
});

export const SettingsAuthenticationContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
	true
)(SettingsAuthentication);
