import { Button, Icon } from '@material-ui/core';
import LaunchIcon from '@material-ui/icons/Launch';
import React from 'react';
import { connect, Dispatch } from '@optum-uhone-hmkts/rise';
import { navigateTo } from '../../actions/navigation_actions';
import { ClientConnectSettingsCard } from '../../components/ClientConnectSettings/ClientConnectSettings';
import IconManagement from '../../components/Image/IconManagement';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { jwt_auth } from '../../utilities/auth';
import { navRoutes } from '../../components/nav/Routes';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import { themeLinks } from '../../utilities/branding';
import { isBrokerage } from '../../utilities/brokerage_utils';
import { Agent } from '../../reducers/agent_reducer';
import { MoreMenu, createHelpAction } from '../../components/nav/more_menu';
import { NameCard } from '../../components/Profile/name_card';
import { BioCard } from '../../components/Profile/bio_card';
import { ContactInformationCard } from '../../components/Profile/contact_information_card';
import { AddressCard } from '../../components/Profile/address_card';
import { TimeZoneCard } from '../../components/Profile/timezone_card';
import { timeZoneStandardToDisplayMap } from '../../utilities/mapping_util';
import { P } from '../../utilities/auth/permissions';
import { WideButton } from '../../components/utility/wide_button';
import { Strings } from '../../assets/common/strings';
import { DialogFrame } from '../../reducers/dialogs_reducer';
import { makeDialogInstance } from '../dialogs';
import { TimeZoneDialog } from '../dialogs/timezone_dialog';
import { QueueDialog } from '../../actions/dialogs_actions';
import { AppointmentWebsiteDialog } from '../dialogs/appointment_website_dialog';
import { NotificationSummary, notificationCategories } from '../../components/notifications/notificationSummary';
import { AppState } from '../../reducers';
import { openExternalLink } from '../../utilities';
import { getAgentContactInfo } from '../../utilities/agent_util';
import { DesktopPadding } from '../../components/Layout/desktop_padding';

interface StateProps {
	agentCode: string;
	agent: Agent;
}
interface DispatchProps {
	queueDialog: (dialog: DialogFrame) => void;
	navigateTo: (route: string) => void;
}

type Props = StateProps & DispatchProps;

const AboutMe: React.FC<Props> = (props) => {

	const { agent, agentCode, queueDialog, navigateTo } = props;

	const openInResourceCenter = () => {
		openExternalLink(themeLinks.rcLinkMyProfile);
	};
	const openEditAppointmentWebsiteDialog = () => {
		queueDialog(makeDialogInstance(AppointmentWebsiteDialog));
	};
	const openEditTimeZoneDialog = () => {
		queueDialog(makeDialogInstance(TimeZoneDialog));
	}

	const editInResourceCenter = (
		<Button onClick={openInResourceCenter} endIcon={<LaunchIcon />}>
			Edit
		</Button>
	);

	const { addresses = [], appointmentWebsite = '' } = agent;
	const businessAddress = addresses.find(address => address.addressType === Strings.AddressType.BusinessAddress);
	const homeAddress = addresses.find(address => address.addressType === Strings.AddressType.HomeAddress);
	const { businessEmail, personalEmail, businessPhone, personalPhone } = getAgentContactInfo(agent);

	return (
		<BasePageContainer
			topComponent={
				<HeaderBarComponent
					title={Strings.AboutMeLabels.AboutMe}
					rightButtons={
						<MoreMenu
							children={[createHelpAction(themeLinks.helpLinkProfile)]}
						/>
					}
				/>
			}
		>
			<PersistentNotifications />
			<NotificationSummary categorySelector={notificationCategories.profile} />
			<DesktopPadding>
				<NameCard
					title="Name and Preferred Name"
					agent={agent}
					startCollapsed={false}
					actions={[editInResourceCenter]}
				/>
				{!isBrokerage &&
					<BioCard
						title="My Bio"
						agent={agent}
						startCollapsed={true}
						actions={[editInResourceCenter]}
					/>
				}
				{!isBrokerage &&
					<ContactInformationCard
						title="Contact Information"
						agent={agent}
						startCollapsed={true}
						businessEmail={businessEmail}
						businessPhone={businessPhone}
						personalEmail={personalEmail}
						personalPhone={personalPhone}
						appointmentWebsite={appointmentWebsite}
						handleClickEditAppointmentWebsite={openEditAppointmentWebsiteDialog}
						actions={[editInResourceCenter]}
					/>
				}
				<AddressCard
					title="Address"
					businessAddress={businessAddress}
					startCollapsed={true}
					homeAddress={homeAddress}
					actions={[editInResourceCenter]}
					noPadding
				/>

				<TimeZoneCard
					title={Strings.AboutMeFields.TimeZone}
					startCollapsed={true}
					timeZoneDisplayString={timeZoneStandardToDisplayMap.get(agent.timeZone) || agent.timeZone}
					handleClickEdit={openEditTimeZoneDialog}
				/>

				<WideButton
					primary="View Hierarchy"
					onClick={() => navigateTo(navRoutes.myHierarchy.path)}
				/>

				{!isBrokerage &&
					<WideButton
						primary="View Live Transfer Phone Numbers"
						secondary="View/manage your live transfer phone numbers"
						onClick={openInResourceCenter}
						icon={<Icon>launch</Icon>}
					/>
				}
				{jwt_auth.hasPermission(P.IconManagement) && !isBrokerage ? <IconManagement /> : null}
				{jwt_auth.hasPermission(P.ClientConnect) && !isBrokerage && (
					<ClientConnectSettingsCard
						personalPhone={personalPhone}
						businessPhone={businessPhone}
						businessEmail={businessEmail}
					/>
				)}
			</DesktopPadding>
		</BasePageContainer>
	);
}

const mapStateToProps = (state: AppState): StateProps => ({
	agentCode: state.user.agentID,
	agent: state.agent,
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	navigateTo: (route: string) =>
		dispatch(navigateTo(route)),
	queueDialog: (dialog: DialogFrame) =>
		dispatch(QueueDialog(dialog)),
});

export const AboutMeContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true,
)(AboutMe);
