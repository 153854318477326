import {
	AcceptTermsAndConditions,
	GetUserById,
	DoneWatchingVideo,
	GetUserByAgentCode,
	UpdateDesktopCallSettings,
	UpdateDeviceSettings,
} from '../actions/user_actions';
import { Action as ReduxAction } from 'redux';
import { isType } from 'typescript-fsa';
import {
	StoreCalendarSettings,
	SyncCalendar,
	AcceptWelcomeVideo,
} from '../actions/user_actions';
import { DisableCalendarSync } from '../actions/calendar_actions';
import { Logout, ImpersonateJwt, FailedLogin, SuccessfulLogin } from '../actions/authentication_actions';
import { jwt_auth } from '../utilities/auth/';
import { getDeviceId } from '../utilities/device_util';
import { VerifyCallerId } from '../actions/click_to_call_actions';

export type User = {
	id: string;
	acceptanceDate?: string;
	agentID: string;
	devices: DeviceSettings[];
	currentDevice: DeviceSettings;
	enableDesktopCalls: boolean;
	enableDesktopTexts: boolean;
	googleCalendarSyncToken?: string;
	googleRefreshToken?: string;
	loading: boolean;
	syncGoogleAppointments?: boolean;
	syncGooglePhoneCalls?: boolean;
	syncGoogleTasks?: boolean;
	videoAcceptanceDate: string;
	videoFullScreen: boolean;
	impersonatingId: string;
	c2cAcceptanceDate?: Date;
	callerId: string;
	callerIdVerificationSid: string;
};

export type DeviceSettings = {
	announcements: boolean;
	battles: boolean;
	clientConnect: boolean;
	crm: boolean;
	documents: boolean;
	finances: boolean;
	general: boolean;
	leads: boolean;
	notificationsEnabled: boolean;
	performance: boolean;
	profile: boolean;
	settings: boolean;
	todoList: boolean;
	tools: boolean;
	training: boolean;
	deviceID: string;
	fcmKey: string[];
	[key: string]: string | boolean | string[];
};

export const initialDeviceSettings = {
	deviceID: '',
	general: true,
	announcements: true,
	battles: true,
	clientConnect: true,
	crm: true,
	documents: true,
	finances: true,
	leads: true,
	performance: true,
	profile: true,
	settings: true,
	todoList: true,
	tools: true,
	training: true,
	notificationsEnabled: true,
	fcmKey: [],
}

export enum DevicePermissionSettings {
	General = 'general',
	Battles = 'battles',
	ClientConnect = 'clientConnect',
	Crm = 'crm',
	Documents = 'documents',
	Finances = 'finances',
	Leads = 'leads',
	Performance = 'performance',
	Profile = 'profile',
	Settings = 'settings',
	TodoList = 'todoList',
	Tools = 'tools',
	Training = 'training',
};

const initialState: User = {
	agentID: '',
	id: '',
	impersonatingId: '',
	devices: [],
	currentDevice: initialDeviceSettings,
	syncGoogleAppointments: false,
	syncGooglePhoneCalls: false,
	syncGoogleTasks: false,
	googleCalendarSyncToken: '',
	googleRefreshToken: '',
	loading: false,
	videoAcceptanceDate: '',
	videoFullScreen: false,
	enableDesktopCalls: false,
	enableDesktopTexts: false,
    c2cAcceptanceDate: undefined,
	callerId: '',
	callerIdVerificationSid: '',
};

export function userReducer(
	state: User = initialState,
	action: ReduxAction
): User {
	if (isType(action, UpdateDeviceSettings.started)) {
		return {
			...state,
			currentDevice: action.payload,
		};
	} else if (isType(action, UpdateDeviceSettings.done)) {
		const updatedDevice = action.payload.params;
		const cachedDevices = state.devices.slice();

		const updatedDeviceIndex = cachedDevices.findIndex(device => device.deviceID == updatedDevice.deviceID);

		if (updatedDeviceIndex > -1) {
			cachedDevices[updatedDeviceIndex] = updatedDevice;
		} else {
			cachedDevices.push(updatedDevice);
		}

		return {
			...state,
			devices: cachedDevices,
		};
	} else if (isType(action, ImpersonateJwt)) {
		const decodedJwt = jwt_auth.decodeJwt(action.payload);
		return {
			...state,
			impersonatingId: decodedJwt.impersonatingId || "",
		}
	} else if (isType(action, SuccessfulLogin)) {
		const decodedJwt = jwt_auth.decodeJwt(action.payload.jwt);
		let impersonatingId = '';
		if (decodedJwt.impersonatingId && decodedJwt.impersonatingId !== decodedJwt.userID) {
			impersonatingId = decodedJwt.impersonatingId;
		}
		return {
			...state,
			impersonatingId,
		}
	} else if (isType(action, AcceptTermsAndConditions)) {
		return {
			...state,
			acceptanceDate: `${new Date()}`,
		};
	} else if (isType(action, Logout.started) || isType(action, FailedLogin)) {
		return {
			...initialState,
		};
	} else if (isType(action, StoreCalendarSettings.started)) {
		return {
			...state,
		};
	} else if (isType(action, StoreCalendarSettings.done)) {
		const result = action.payload.result;
		return {
			...state,
			syncGoogleAppointments: result.syncGoogleAppointments,
			syncGooglePhoneCalls: result.syncGooglePhoneCalls,
			syncGoogleTasks: result.syncGoogleTasks,
			googleCalendarSyncToken: result.googleCalendarSyncToken,
			googleRefreshToken: result.googleRefreshToken,
		};
	} else if (isType(action, StoreCalendarSettings.failed)) {
		return {
			...state,
		};
	} else if (isType(action, SyncCalendar.started)) {
		return {
			...state,
		};
	} else if (isType(action, SyncCalendar.done)) {
		const result = action.payload.result;
		return {
			...state,
			syncGoogleAppointments: result.syncGoogleAppointments,
			syncGooglePhoneCalls: result.syncGooglePhoneCalls,
			syncGoogleTasks: result.syncGoogleTasks,
			googleCalendarSyncToken: result.googleCalendarSyncToken,
			googleRefreshToken: result.googleRefreshToken,
		};
	} else if (isType(action, SyncCalendar.failed)) {
		return {
			...state,
		};
	} else if (isType(action, GetUserById.done) || isType(action, GetUserByAgentCode.done)) {
		const deviceId = getDeviceId();
		const device = action.payload.result.devices.find(d => d.deviceID == deviceId);

		return {
			...state,
			...action.payload.result,
			currentDevice: device || initialDeviceSettings,
		};
	} else if (isType(action, GetUserById.failed)) {
		return {
			...state,
		};
	} else if (isType(action, DisableCalendarSync.started)) {
		return {
			...state,
		};
	} else if (isType(action, DisableCalendarSync.done)) {
		return {
			...state,
			googleRefreshToken: '',
			googleCalendarSyncToken: '',
		};
	} else if (isType(action, DisableCalendarSync.failed)) {
		return {
			...state,
		};
	} else if (isType(action, AcceptWelcomeVideo)) {
		return {
			...state,
			videoAcceptanceDate: new Date().toString(),
		};
	} else if (isType(action, DoneWatchingVideo)) {
		return {
			...state,
			videoFullScreen: false,
		};
	} else if (isType(action, VerifyCallerId.done)) {
		return {
			...state,
			callerId: action.payload.result ? action.payload.params : state.callerId,
		};
	} else if (isType(action, UpdateDesktopCallSettings.started)) {
		return {
			...state,
			loading: true,
		};
	} else if (isType(action, UpdateDesktopCallSettings.failed)) {
		return {
			...state,
			loading: false,
		};
	} else if (isType(action, UpdateDesktopCallSettings.done)) {
		return {
			...state,
			loading: false,
			enableDesktopCalls: action.payload.params.enableDesktopCalls,
			enableDesktopTexts: action.payload.params.enableDesktopTexts
		};
	} else {
		return state;
	}
}