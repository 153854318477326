import {actionCreatorFactory} from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';
import { User, DeviceSettings } from '../reducers/user_reducer';
import { submitLog } from '../utilities/logging_util';
import { Strings } from '../assets/common/strings';

const actionCreator = actionCreatorFactory('User');

export const AcceptTermsAndConditions = actionCreator('ACCEPT_TERMS_AND_CONDITIONS');
export const AcceptWelcomeVideo = actionCreator('ACCEPT_WELCOME_VIDEO');
export const DoneWatchingVideo = actionCreator('DONE_WATCHING_VIDEO');

export const GetUserByAgentCode = actionCreator.async<string, User>("GET_USER_BY_AGENT_CODE")
export interface GetUserByIdParams { id: string; sync?: boolean }
export const GetUserById = actionCreator.async<GetUserByIdParams, User>('GET_USER_BY_ID');
export const StoreCalendarSettings = actionCreator.async<User, User>('STORE_CALENDAR_SETTINGS');
export const SyncCalendar = actionCreator.async<undefined, User>('SYNC_CALENDAR');
export const UpdateDeviceSettings = actionCreator.async<DeviceSettings, void>('UPDATE_DEVICE_SETTINGS');
export const UpdateDeviceFcm = actionCreator('UPDATE_DEVICE_FCM');
export const UpdateDesktopCallSettings = actionCreator.async<User, void>('UPDATE_DESKTOP_CALL_SETTINGS');
export const UpdateClickToCallSettings = actionCreator.async<User, void>('UPDATE_CLICK_2_CALL_SETTINGS');

export function storeCalendarSettingsSuccess(
	user: User,
	response: User
): ReduxAction {
	return StoreCalendarSettings.done({
		params: user,
		result: response,
	});
}

export function storeCalendarSettingsFailure(
	user: User,
	error: any
): ReduxAction {
	submitLog(Strings.Log.Error, `Error storing google calendar settings`, {
		error,
		userId: user.id,
		impersonatingId: user.impersonatingId,
	});
	return StoreCalendarSettings.failed({
		params: user,
		error: error,
	});
}

export function syncCalendar(): ReduxAction {
	return SyncCalendar.started(undefined);
}

export function syncCalendarSuccess(user: User): ReduxAction {
	return SyncCalendar.done({
		params: undefined,
		result: user,
	});
}

export function syncCalendarFailure(error: any): ReduxAction {
	submitLog(Strings.Log.Error, `Error syncing google calendar`, { error });
	return SyncCalendar.failed({
		params: undefined,
		error: error,
	});
}


// GET USER

export function getUserById(id: string, sync?: boolean): ReduxAction {
	return GetUserById.started({ id: id, sync: sync });
}

export function getUserByIdSuccess(
	id: string,
	user: User,
	sync?: boolean
): ReduxAction {
	return GetUserById.done({
		params: {
			id: id,
			sync: sync,
		},
		result: user,
	});
}

export function getUserByFailure(
	id: string,
	error: any,
	sync?: boolean
): ReduxAction {
	submitLog(Strings.Log.Error, `Error getting user from web api`, {
		error,
		userId: id,
		syncing: sync,
	});
	return GetUserById.failed({
		params: {
			id: id,
			sync: sync,
		},
		error: error,
	});
}
